import React, { useEffect, useState, useRef } from 'react';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
let i = 0;
let updateCarouselInterval;
let updateCarouselCountInterval;

const Testimonials = props => {
  const carouselRef = useRef(i);
  carouselRef.current = i;
  console.log(carouselRef);

  useEffect(() => {
    updateCarousel();
    updateCarouselCount();

    updateCarouselInterval = setInterval(updateCarousel, 100);
    updateCarouselCountInterval = setInterval(updateCarouselCount, 5000);

    return () => {
      clearInterval(updateCarouselInterval);
      clearInterval(updateCarouselCountInterval);
    };
  }, []);

  function updateCarousel() {
    const carouselItems = document.querySelectorAll('.carousel_item');
    const carouselNavItems = document.querySelectorAll('.carousel_nav_item');
    Array.from(carouselItems).forEach((item, index) => {
      if (carouselRef.current < carouselItems.length) {
        item.style.transform = `translateX(-${carouselRef.current * 100}%)`;
      }
    });

    Array.from(carouselNavItems).forEach((item, index) => {
      item.classList.remove('active');
      if (index === carouselRef.current) {
        item.classList.add('active');
      }
    });
  }

  function updateCarouselCount() {
    const carouselItems = document.querySelectorAll('.carousel_item');

    if (carouselRef.current < carouselItems.length - 1) {
      carouselRef.current++;
    } else {
      carouselRef.current = 0;
    }
  }

  return (
    <>
      <Container fluid className={props.bgcolor}>
        <div className="landing-gutter">
          <h1 className="landing-header">
            What our customers <span className="text-red">say</span>
          </h1>
          <div className="carousel-container">
            <div className="carousel_items">
              {props.children.map((child, index) => {
                return (
                  <div className="carousel_item" key={index}>
                    {child}
                  </div>
                );
              })}
            </div>
            <div className="carousel_nav">
              {props.children.map((child, index) => {
                return (
                  <div
                    className={`carousel_nav_item`}
                    key={index}
                    onClick={e => {
                      carouselRef.current = index;
                      // clearInterval(updateCarouselCountInterval);
                    }}
                  ></div>
                );
              })}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

Testimonials.propTypes = {
  bgcolor: PropTypes.string,
  children: PropTypes.node
};

export default Testimonials;
