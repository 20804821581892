import React from 'react';
import PropTypes from 'prop-types';

const Testimonial = ({ children }) => {
  return <div className="testimonial-wrapper">{children}</div>;
};

const Quote = ({ children }) => {
  return (
    <p className="testimonial-quote">
      <span className="text-red">"</span>
      {children}
      <span className="text-red">"</span>
    </p>
  );
};

const Image = ({ children, alt }) => {
  return <img src={children} alt={alt} className="testimonial-image" />;
};

const Name = ({ children }) => {
  return <p className="testimonial-name">{children}</p>;
};

const Company = ({ children }) => {
  return <p className="testimonial-company">{children}</p>;
};

Testimonial.Quote = Quote;
Testimonial.Image = Image;
Testimonial.Name = Name;
Testimonial.Company = Company;

Testimonial.propTypes = {
  children: PropTypes.any
};

Quote.propTypes = {
  children: PropTypes.any
};

Image.propTypes = {
  children: PropTypes.any,
  alt: PropTypes.string
};

Name.propTypes = {
  children: PropTypes.any
};

Company.propTypes = {
  children: PropTypes.any
};

export default Testimonial;
