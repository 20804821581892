import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const App = ({ children, path }) => {
  const navigate = useNavigate();
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {children}
      <Button
        className="ourapps-learnmore"
        variant="danger"
        style={{ marginTop: 'auto' }}
        onClick={() => {
          window.scrollTo(0, 0);
          navigate(path);
        }}
      >
        Learn More <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
      </Button>
    </div>
  );
};

const AppTitle = ({ children }) => {
  return <h1 className="ourapps-apptitle">{children}</h1>;
};

const AppSubTitle = ({ children }) => {
  return <h2 className="ourapps-subtitle">{children}</h2>;
};

const AppDescription = ({ children }) => {
  return <div>{children}</div>;
};

App.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string
};

AppTitle.propTypes = {
  children: PropTypes.any
};

AppSubTitle.propTypes = {
  children: PropTypes.any
};

AppDescription.propTypes = {
  children: PropTypes.any
};

App.Title = AppTitle;
App.SubTitle = AppSubTitle;
App.Description = AppDescription;

export default App;
