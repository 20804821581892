import { useTrackingCode } from 'react-hubspot-tracking-code-hook';

export const trackHubSpotPageView = (title, path) => {
  const { setPathPageView } = useTrackingCode();
  if (title != document.title) {
    setTimeout(() => {
      trackHubSpotPageView(title, path);
    }, 500);
    return;
  }
  setPathPageView(path);
};
