import hubspotgold from 'assets/img/landingpage/hubspotgold.png';
import React from 'react';
import { Col, Container, Nav, NavDropdown, Row } from 'react-bootstrap';
// import logowhiteout from 'assets/img/landingpage/logo-whiteout.svg';
import { getUTMUrl } from 'helpers/utils';
import AnimateCC from 'react-adobe-animate';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <Container fluid className="bg-blue footer">
      <div className="landing-gutter" style={{ paddingBottom: 90 }}>
        <Row>
          <Col lg={7}>
            <p className="footer-text text-bold">Pacific Marketing Labs Ltd</p>
            <p className="footer-text">
              Jordans Courtyard, 8 Upper High St. Thame, Oxfordshire, UK, OX9
              3ER
              <br />
            </p>
            <p className="footer-text">
              Registered in England, Company No: 14527300
              <br />
              VAT Registration No: GB 430 6635 13
              <br />
              UK Data Protection Registration: ZB504724
            </p>
            <p className="footer-text">
              <a
                className="footer-text"
                target="_blank"
                rel="noreferrer"
                href="https://www.pacificmarketinglabs.io/Privacy-Policy-Pacific-Marketing-Labs-Jan-23.pdf"
              >
                Privacy Policy
              </a>
            </p>
            {/* <img
              src={hubspotgold}
              className="footer-hubspot"
              alt="HubSpot gold partner award"
            /> */}
          </Col>
          <Col lg={3}>
            <a className="footer-text text-bold" href="/">
              Home
            </a>
            <br />
            <br />
            <Nav.Item>
              <NavDropdown title="Apps" id="navbarScrollingDropdown">
                <div className="py-2 bg-light rounded-3">
                  <NavDropdown.Item as={Link} to="/valid8">
                    Valid8
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/currency-r8">
                    Currency-R8
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/activity-m8">
                    Activity-M8
                  </NavDropdown.Item>
                </div>
              </NavDropdown>
            </Nav.Item>
            <Nav.Item>
              <NavDropdown title="Pricing" id="navbarScrollingDropdown">
                <div className="py-2 bg-light rounded-3">
                  <NavDropdown.Item as={Link} to="/valid8#pricing">
                    Valid8
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/currency-r8#pricing">
                    Currency-R8
                  </NavDropdown.Item>

                  <NavDropdown.Item as={Link} to="/activity-m8#pricing">
                    Activity-M8
                  </NavDropdown.Item>
                </div>
              </NavDropdown>
            </Nav.Item>
            <a className="footer-text text-bold" href="/help">
              Help Centre
            </a>
            <br />
            <br />
            <a className="footer-text text-bold" href="/partner">
              Partner
            </a>
            <br />
            <br />
            <a className="footer-text text-bold" href="/contact">
              Contact
            </a>
            <br />
            <br />
            <a
              className="footer-text text-bold"
              href="https://app.pacificmarketinglabs.io/login"
              target="blank"
            >
              Login
            </a>
            <br />
            <br />
            <a
              className="footer-text text-bold"
              href={`${process.env.REACT_APP_BASE_URL}/signup`}
              target="blank"
            >
              Get Started
            </a>
          </Col>
          <Col lg={2}>
            <img
              src="./images/logo-white.png"
              className="footer-pmltd"
              alt="Pacific Marketing Labs Logo"
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Footer;
