import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const UTMStore = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    storeUTMParams();
  }, []);

  const storeUTMParams = () => {
    let utms;
    const existingUtms = JSON.parse(localStorage.getItem('utms'));

    if (existingUtms) {
      if (
        searchParams.get('utm_source') == null &&
        searchParams.get('utm_medium') == null &&
        searchParams.get('utm_campaign') == null &&
        searchParams.get('utm_term') == null &&
        searchParams.get('utm_content') == null
      ) {
        return;
      } else {
        utms = {
          source: searchParams.get('utm_source'),
          medium: searchParams.get('utm_medium'),
          campaign: searchParams.get('utm_campaign'),
          term: searchParams.get('utm_term'),
          content: searchParams.get('utm_content')
        };
        localStorage.setItem('utms', JSON.stringify(utms));
      }
    } else {
      utms = {
        source: searchParams.get('utm_source'),
        medium: searchParams.get('utm_medium'),
        campaign: searchParams.get('utm_campaign'),
        term: searchParams.get('utm_term'),
        content: searchParams.get('utm_content')
      };
      localStorage.setItem('utms', JSON.stringify(utms));
    }
    setSearchParams({});
  };

  return children;
};

export default UTMStore;
