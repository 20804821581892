import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { Container, Navbar } from 'react-bootstrap';
import LandingRightSideNavItem from './LandingRightSideNavItem';
import { topNavbarBreakpoint } from 'config';
import AppContext from 'context/Context';
import logo from 'assets/img/logo.png';
import AnimateCC from 'react-adobe-animate';

const NavbarStandard = () => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);

  return (
    <Navbar
      variant="light"
      // fixed="top"
      expand={topNavbarBreakpoint}
      className={classNames('navbar-standard navbar-theme', {
        'bg-100': !navbarCollapsed && isDark,
        'bg-dark': !navbarCollapsed && !isDark
      })}
      bg="white"
    >
      <Container fluid className="container-navbar">
        <Navbar.Toggle onClick={() => setNavbarCollapsed(!navbarCollapsed)} />
        {/* <img src={logo} width={100} height={100}></img> */}
        <div style={{ width: 100, height: 100 }}>
          <AnimateCC animationName="LabsLogoBlue" paused={false} />
        </div>

        <Navbar.Collapse className="scrollbar">
          <LandingRightSideNavItem />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarStandard;
