import React, { useState } from 'react';
import {
  Button,
  Card,
  Nav,
  NavDropdown,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

const breakpoint = 'lg';

const LandingRightSideNavItem = () => {
  const navigate = useNavigate();

  const getUTMCodes = () => {
    const utms = JSON.parse(localStorage.getItem('utms'));
    if (utms) {
      return (
        'utm_source=' +
        utms.source +
        '&utm_medium=' +
        utms.medium +
        '&utm_campaign=' +
        utms.campaign +
        '&utm_term=' +
        utms.term +
        '&utm_content=' +
        utms.content
      );
    } else {
      return '';
    }
  };

  return (
    <Nav navbar className="ms-auto">
      <Nav.Item>
        <Nav.Link as={Link} to="/">
          Home
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <NavDropdown title="Apps" id="navbarScrollingDropdown">
          <div className="py-2 bg-light rounded-3">
            <NavDropdown.Item as={Link} to="/valid8">
              Valid8
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/currency-r8">
              Currency-R8
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/activity-m8">
              Activity-M8
            </NavDropdown.Item>
          </div>
        </NavDropdown>
      </Nav.Item>
      <Nav.Item>
        <NavDropdown title="Pricing" id="navbarScrollingDropdown">
          <div className="py-2 bg-light rounded-3">
            <NavDropdown.Item as={Link} to="/valid8#pricing">
              Valid8
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/currency-r8#pricing">
              Currency-R8
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/activity-m8#pricing">
              Activity-M8
            </NavDropdown.Item>
          </div>
        </NavDropdown>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/help">
          Help Centre
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/partner">
          Partner
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/contact">
          Contact
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          href={process.env.REACT_APP_APP_URL + '/login'}
          target="_blank"
        >
          Login
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Button
          href={`/signup`}
          target="_blank"
          variant="danger"
          className="signup-btn"
        >
          Get Started
        </Button>
      </Nav.Item>
    </Nav>
  );
};

export default LandingRightSideNavItem;
