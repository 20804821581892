import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const bgColors = {
  lightblue: 'bg-lightblue',
  blue: 'bg-blue',
  grey75: 'bg-grey75',
  grey50: 'bg-grey50',
  grey25: 'bg-grey25',
  porcelain: 'bg-porcelain',
  grey10: 'bg-grey10'
};

const AppFeature = ({
  children,
  bgcolor,
  anchor = false,
  gutterStyle = {},
  containerStyle = {}
}) => {
  return (
    <Container fluid className={bgColors[bgcolor]} style={containerStyle}>
      {anchor && (
        <div id="findoutmoreanchor" style={{ visibility: 'hidden' }}></div>
      )}
      <div className="landing-gutter" style={gutterStyle}>
        {children}
      </div>
    </Container>
  );
};

const Title = ({ children }) => {
  return <h1 className="landing-header">{children}</h1>;
};

const Content = ({ children, imgpos, imgurl, imgMaxWidth, videourl }) => {
  if (imgpos == 'S') {
    return (
      <>
        <div>{children}</div>
        <div>
          <img src={imgurl} className="appfeature-img-s" />
        </div>
      </>
    );
  } else if (imgpos == 'E') {
    return (
      <Row>
        <Col lg={6}>
          <div>{children}</div>
        </Col>
        <Col
          lg={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center'
          }}
        >
          {imgurl && (
            <img
              src={imgurl}
              className="appfeature-img-e"
              style={{ maxWidth: imgMaxWidth }}
            />
          )}
          {videourl && (
            <div className="aspect-ratio-16x9">
              <video
                loop
                autoPlay
                muted
                controls
                playsInline
                className="help-youtube-iframe"
              >
                <source src={videourl} type="video/mp4" />
              </video>
            </div>
          )}
        </Col>
      </Row>
    );
  }
};

AppFeature.Title = Title;
AppFeature.Content = Content;

AppFeature.propTypes = {
  anchor: PropTypes.bool,
  children: PropTypes.any.isRequired,
  bgcolor: PropTypes.string,
  gutterStyle: PropTypes.object,
  containerStyle: PropTypes.object
};

Title.propTypes = {
  children: PropTypes.any.isRequired
};

Content.propTypes = {
  children: PropTypes.any.isRequired,
  imgpos: PropTypes.string,
  imgurl: PropTypes.string,
  imgMaxWidth: PropTypes.string,
  videourl: PropTypes.string
};

export default AppFeature;
