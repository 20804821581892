import { trackHubSpotPageView } from 'helpers/hubspot';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Helmet from 'react-helmet';

import Footer from 'components/landing/Footer';
import NavbarStandard from 'components/landing/NavbarStandard';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { Container } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

const Help = () => {
  const pageTitle = 'Pacific Marketing Labs - Help';
  const [activeTab, setActiveTab] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const breakpoints = useBreakpoints();

  const isSnap = navigator.userAgent.toLowerCase().includes('reactsnap');

  useEffect(() => {
    if (isSnap) {
      return;
    }
    trackHubSpotPageView('Pacific Marketing Labs - Help', '/help');
    const hsMeetingScript = document.createElement('script');

    hsMeetingScript.src =
      'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    hsMeetingScript.async = true;

    document.body.appendChild(hsMeetingScript);

    return () => {
      document.body.removeChild(hsMeetingScript);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: process.env.REACT_APP_PORTAL_ID,
          formId: process.env.REACT_APP_SUPPORT_FORM,
          target: '#supportForm'
        });
      }
    });
  }, []);

  useEffect(() => {
    let tab = location.pathname.split('/')[2];
    if (tab === undefined) {
      navigate('valid8');
    } else {
      setActiveTab(tab);
    }
  }, [location]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>\
        <meta
          name="description"
          content="Support for Pacific Marketing Labs. Help with Valid8 and Currency-R8 HubSpot apps."
        />
        <meta
          name="keywords"
          content="Currency-R8, Valid8, Currency-R8 Help, Valid8 Help, Pacific Marketing Labs support, Hubspot Currency Conversion support, HubSpot email verficication support"
        />
      </Helmet>
      <div className="landing-page" id="helppage">
        <NavbarStandard />
        <div
          className="landing-gutter"
          style={{
            minHeight: '50vh',
            maxWidth: 'calc(100vw-144px)',
            backgroundColor: '#fff'
          }}
        >
          <h1 className="landing-header" style={{ paddingTop: 90 }}>
            HELP <span className="text-red">CENTRE</span>
          </h1>
          <p className="apphero-description" style={{ marginBottom: 0 }}>
            Welcome to our Help Centre where you'll find lots of useful videos
            to help you get started with our products. If the video's don't
            answer your questions, please use the form below to{' '}
            <a className="text-red" href={`/help/${activeTab}#supportForm`}>
              log a ticket
            </a>{' '}
            with our support team.
          </p>
          <h2 style={{ paddingTop: 40 }} className="landing-header">
            Videos
          </h2>
          <Tabs
            id="helpTab"
            activeKey={activeTab}
            style={{ height: '100%' }}
            onSelect={t => {
              navigate('/help/' + t);
            }}
          >
            <Tab
              eventKey="valid8"
              title="Valid8"
              className="border-bottom border-x p-3"
            >
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div className="youtube-wrapper">
                  <div className="aspect-ratio-16x9">
                    <iframe
                      className="help-youtube-iframe"
                      src="https://www.youtube.com/embed/hWeQpV36FAc"
                      title="Pacific Marketing Labs : Valid8 HubSpot App"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <p>Valid8 HubSpot App</p>
                </div>
                <div className="youtube-wrapper">
                  <div className="aspect-ratio-16x9">
                    <iframe
                      className="help-youtube-iframe"
                      src="https://www.youtube.com/embed/uh3WB1oa5GE"
                      title="Pacific Marketing Labs : Valid8 Installation Guide"
                      frameBorder="0"
                      allow="accelerometer; autoplay;  encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <p>Valid8 Installation Guide</p>
                </div>
                <div className="youtube-wrapper">
                  <div className="aspect-ratio-16x9">
                    <iframe
                      className="help-youtube-iframe"
                      src="https://www.youtube.com/embed/EZdRojlV380"
                      title="Pacific Marketing Labs : Using Valid8 to verify ALL your HubSpot contacts&#39; email addresses"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <p>How to validate ALL your HubSpot Contacts using Valid8</p>
                </div>
                <div className="youtube-wrapper">
                  <div className="aspect-ratio-16x9">
                    <iframe
                      className="help-youtube-iframe"
                      src="https://www.youtube.com/embed/7BY32a83qQU"
                      title="Pacific Marketing Labs : Using Valid8 to check a contact's email address"
                      frameBorder="0"
                      allow="accelerometer; autoplay;  encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <p>Using Valid8 to check an email address</p>
                </div>
                <div className="youtube-wrapper">
                  <div className="aspect-ratio-16x9">
                    <iframe
                      src="https://www.youtube.com/embed/DpvwR_SHcBM"
                      title="Pacific Marketing Labs : Using Valid8 within HubSpot workflows"
                      className="help-youtube-iframe"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <p>Using Valid8 within HubSpot workflows</p>
                </div>
                <div className="youtube-wrapper">
                  <div className="aspect-ratio-16x9">
                    <iframe
                      className="help-youtube-iframe"
                      src="https://www.youtube.com/embed/xJexEK8HFO0"
                      title="Pacific Marketing Labs : Using Valid8 to quarantine bad emails"
                      frameBorder="0"
                      allow="accelerometer; autoplay;  encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <p>Using Valid8 to quarantine bad emails</p>
                </div>
                <div className="youtube-wrapper">
                  <div className="aspect-ratio-16x9">
                    <iframe
                      className="help-youtube-iframe"
                      src="https://www.youtube.com/embed/vtkI094r0po"
                      title="Pacific Marketing Labs : How to build a Valid8 dashboard in HubSpot"
                      frameBorder="0"
                      allow="accelerometer; autoplay;  encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <p>How to build a Valid8 dashboard in HubSpot</p>
                </div>
                <div className="youtube-wrapper">
                  <div className="aspect-ratio-16x9">
                    <iframe
                      className="help-youtube-iframe"
                      src="https://www.youtube.com/embed/5NJscl7J6n0"
                      title="Pacific Marketing Labs: How to uninstall Valid8"
                      frameBorder="0"
                      allow="accelerometer; autoplay;  encrypted-media; gyroscope; picture-in-picture; "
                      allowFullScreen
                    ></iframe>
                  </div>
                  <p>How to uninstall Valid8</p>
                </div>
                <div className="youtube-wrapper">
                  <div className="aspect-ratio-16x9">
                    <iframe
                      className="help-youtube-iframe"
                      src="https://www.youtube.com/embed/mAv24ge30Wg"
                      title="Pacific Marketing Labs: Agencies, Managing Multiple Clients"
                      frameBorder="0"
                      allow="accelerometer; autoplay;  encrypted-media; gyroscope; picture-in-picture; "
                      allowFullScreen
                    ></iframe>
                  </div>
                  <p>Managing Multiple Clients</p>
                </div>
                {/* Used to pad empty space on all widths */}
                {breakpoints.width >= 992 && (
                  <>
                    <div className="youtube-wrapper"></div>
                    <div className="youtube-wrapper"></div>
                  </>
                )}
                {breakpoints.width >= 576 && (
                  <>
                    <div className="youtube-wrapper 576">
                      <div className="aspect-ratio-16x9"></div>
                    </div>
                  </>
                )}
              </div>
            </Tab>
            <Tab
              eventKey="currency-r8"
              title="Currency-R8"
              className="border-bottom border-x p-3"
            >
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div className="youtube-wrapper">
                  <div className="aspect-ratio-16x9">
                    <iframe
                      className="help-youtube-iframe"
                      src="https://www.youtube.com/embed/xGC1DY8tbmw"
                      title="Pacific Marketing Labs : Currency-R8 HubSpot App"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <p>Currency-R8 HubSpot App</p>
                </div>
                <div className="youtube-wrapper">
                  <div className="aspect-ratio-16x9">
                    <iframe
                      className="help-youtube-iframe"
                      src="https://www.youtube.com/embed/M3OCLCp9Vhs"
                      title="Pacific Marketing Labs : Currency-R8 Installation Guide"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <p>Currency-R8 Installation Guide</p>
                </div>
                <div className="youtube-wrapper">
                  <div className="aspect-ratio-16x9">
                    <iframe
                      className="help-youtube-iframe"
                      src="https://www.youtube.com/embed/mtpgehZHuic"
                      title="Pacific Marketing Labs : Using Currency-R8 to update a deal"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <p>Using Currency-R8 to update a deal</p>
                </div>
                <div className="youtube-wrapper">
                  <div className="aspect-ratio-16x9">
                    <iframe
                      className="help-youtube-iframe"
                      src="https://www.youtube.com/embed/PSdKlVJ7m14"
                      title="Pacific Marketing Labs : Using Currency-R8 within HubSpot workflows"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <p>Using Currency-R8 within HubSpot workflows</p>
                </div>
                <div className="youtube-wrapper">
                  <div className="aspect-ratio-16x9">
                    <iframe
                      className="help-youtube-iframe"
                      src="https://www.youtube.com/embed/SYRXYHN-YEI"
                      title="Pacific Marketing Labs: How to uninstall Currency-R8"
                      frameBorder="0"
                      allow="accelerometer; autoplay;  encrypted-media; gyroscope; picture-in-picture; "
                      allowFullScreen
                    ></iframe>
                  </div>
                  <p>How to uninstall Currency-R8</p>
                </div>
                <div className="youtube-wrapper">
                  <div className="aspect-ratio-16x9">
                    <iframe
                      className="help-youtube-iframe"
                      src="https://www.youtube.com/embed/mAv24ge30Wg"
                      title="Pacific Marketing Labs: Agencies, Managing Multiple Clients"
                      frameBorder="0"
                      allow="accelerometer; autoplay;  encrypted-media; gyroscope; picture-in-picture; "
                      allowFullScreen
                    ></iframe>
                  </div>
                  <p>Managing Multiple Clients</p>
                </div>
                <div className="youtube-wrapper">
                  <div className="aspect-ratio-16x9">
                    <iframe
                      width="1439"
                      height="818"
                      className="help-youtube-iframe"
                      src="https://www.youtube.com/embed/Jj1xICh-g94"
                      title="Pacific Marketing Labs: Currency-R8 Scheduler"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <p>Currency-R8 Scheduler</p>
                </div>
                <div className="youtube-wrapper">
                  <div className="aspect-ratio-16x9"></div>
                  <p></p>
                </div>
                {/* Added empty divs so it doesnt center bottom row on fullwidth */}
                {/* {breakpoints.width >= 992 && (
                  <>
                    <div className="youtube-wrapper">
                      <div className="aspect-ratio-16x9"></div>
                      <p></p>
                    </div>
                  </>
                )} */}
              </div>
            </Tab>
            <Tab
              eventKey="activity-m8"
              title="Activity-M8"
              className="border-bottom border-x p-3"
            >
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div className="youtube-wrapper">
                  <div className="aspect-ratio-16x9">
                    <iframe
                      className="help-youtube-iframe"
                      src="https://www.youtube.com/embed/uLDGPxNRMWI"
                      title="Pacific Marketing Labs : Activity-M8 HubSpot App"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <p>Activity-M8 HubSpot App</p>
                </div>
                <div className="youtube-wrapper 992">
                  <div className="aspect-ratio-16x9">
                    <iframe
                      className="help-youtube-iframe"
                      src="https://www.youtube.com/embed/jB30-gQ5FDc"
                      title="Pacific Marketing Labs : Activity-M8 Installation Guide"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <p>Activity-M8 Installation Guide</p>
                </div>
                <div className="youtube-wrapper 992">
                  <div className="aspect-ratio-16x9">
                    <iframe
                      className="help-youtube-iframe"
                      src="https://www.youtube.com/embed/YHhUbHyFZDU"
                      title="Pacific Marketing Labs: How to uninstall Activity-M8"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <p>How to uninstall Activity-M8</p>
                </div>
                {/* Used to pad empty space on all widths */}
                {/* {breakpoints.width >= 992 && (
                  <>
                    <div className="youtube-wrapper 992">
                      <div className="aspect-ratio-16x9"></div>
                    </div>
                    <div className="youtube-wrapper 992">
                      <div className="aspect-ratio-16x9"></div>
                    </div>
                  </>
                )} */}
                {breakpoints.width >= 576 && (
                  <>
                    <div className="youtube-wrapper 576">
                      <div className="aspect-ratio-16x9"></div>
                    </div>
                  </>
                )}
              </div>
            </Tab>
          </Tabs>
          <h2 style={{ paddingTop: 40 }} className="landing-header">
            Get <span className="text-red">Support</span>
          </h2>
          <p className="apphero-description">
            Please use the form below to log a support ticket.
          </p>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              marginTop: '-40px'
            }}
          >
            <div id="supportForm"></div>
          </div>
        </div>
        <Container fluid className="bg-grey10">
          <div
            className="meetings-iframe-container"
            data-src="https://meetings.hubspot.com/justinshardlow/pacific-marketing-labs-apps-demo?embed=true"
          ></div>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default Help;
