import React from 'react';
import AnimateCC from 'react-adobe-animate';
import { Helmet } from 'react-helmet';

const PageNotFound = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div
        style={{
          height: '100vh',
          backgroundImage:
            'url(/static/media/Background-2-White.786c95585ee8ef6c1a85.png)',
          backgroundSize: 'cover'
        }}
      >
        <div
          style={{
            position: 'absolute',
            justifyContent: 'center',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div style={{ width: 300, alignSelf: 'center' }}>
            <AnimateCC animationName="LabsLogoBlue" paused={false} />
          </div>

          <p>
            For more information please visit{' '}
            <a href={process.env.REACT_APP_BASE_URL} className="text-red">
              {process.env.REACT_APP_BASE_URL.split('https://')[1]}
              {process.env.REACT_APP_BASE_URL.split('http://')[1]}
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
