import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';
import UTMStore from './components/UTMStore';

import 'assets/scss/user.scss';
const App = () => {
  useEffect(() => {
    if (navigator.userAgent === 'ReactSnap') {
      return;
    }
    const hsScript = document.createElement('script');

    hsScript.src = process.env.REACT_APP_TRACKING_CODE;
    hsScript.async = true;

    document.body.appendChild(hsScript);

    return () => {
      document.body.removeChild(hsScript);
    };
  }, []);

  useEffect(() => {
    document.querySelector('html').classList.remove('dark');
  }, []);

  return (
    <Router>
      <UTMStore>
        <Layout />
      </UTMStore>
    </Router>
  );
};

export default App;
