import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import NavbarStandard from 'components/landing/NavbarStandard';
import Footer from 'components/landing/Footer';
import { trackHubSpotPageView } from 'helpers/hubspot';
import { Container } from 'react-bootstrap';

const Partner = () => {
  const pageTitle = 'Pacific Marketing Labs - Partner';
  const isSnap = navigator.userAgent.toLowerCase().includes('reactsnap');

  useEffect(() => {
    if (isSnap) {
      return;
    }
    trackHubSpotPageView('Pacific Marketing Labs - Partner', '/partner');
    const hsMeetingScript = document.createElement('script');

    hsMeetingScript.src =
      'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    hsMeetingScript.async = true;

    document.body.appendChild(hsMeetingScript);

    return () => {
      document.body.removeChild(hsMeetingScript);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: process.env.REACT_APP_PORTAL_ID,
          formId: process.env.REACT_APP_PARTNER_FORM,
          target: '#partnerForm'
        });
      }
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>\
        <meta
          name="description"
          content="Become a Pacific Marketing Labs partner."
        />
        <meta
          name="keywords"
          content="Currency-R8, Valid8, Currency-R8 Help, Valid8 Help, Pacific Marketing Labs support, Hubspot Currency Conversion support, HubSpot email verficication support"
        />
      </Helmet>
      <div className="landing-page" id="helppage">
        <NavbarStandard />
        <div
          className="landing-gutter"
          style={{
            minHeight: '50vh',
            maxWidth: 'calc(100vw-144px)',
            backgroundColor: '#fff'
          }}
        >
          <h1 className="landing-header" style={{ paddingTop: 90 }}>
            Become a <span className="text-red">Partner</span>
          </h1>
          <p className="apphero-description" style={{ marginBottom: 64 }}>
            <ul>
              <li>
                Offer your clients quick and easy solutions to currency
                conversion and email verficication
              </li>
              <li>Use our apps on your own HubSpot portal free of charge*</li>
            </ul>
            Please submit the form below to apply to become a Pacific Marketing
            Labs partner.
          </p>

          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              marginTop: '-40px'
            }}
          >
            <div id="partnerForm"></div>
          </div>
          <div>
            <p
              style={{
                textAlign: 'right',
                marginTop: 32,
                marginBottom: -32,
                fontSize: 9,
                color: '#96a1a7'
              }}
            >
              * usage capped at 500 credits per app per month
            </p>
          </div>
        </div>
        <Container fluid className="bg-grey10">
          <div
            className="meetings-iframe-container"
            data-src="https://meetings.hubspot.com/justinshardlow/pacific-marketing-labs-apps-demo?embed=true"
          ></div>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default Partner;
