import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import is from 'is_js';
import Landing from 'views/Landing';

import Valid8LandingPage from 'views/Valid8LandingPage';
import CR8LandingPage from 'views/CR8LandingPage';
import PageNotFound from 'views/errors/PageNotFound';
import Help from 'views/Help';
import Contact from 'views/Contact';
import Partner from 'views/Partner';
import Signup from 'views/Signup';
import ActivityM8LandingPage from 'views/ActivityM8LandingPage';
import Valid8SpinWheel from 'views/Valid8SpinWheel';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/help/*" element={<Help />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/partner" element={<Partner />} />
        <Route path="/valid8" element={<Valid8LandingPage />} />
        <Route path="/valid8/spin-to-win" element={<Valid8SpinWheel />} />
        <Route path="/activity-m8" element={<ActivityM8LandingPage />} />
        <Route path="/currency-r8" element={<CR8LandingPage />} />
        <Route path="/page-not-found" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/page-not-found" replace />} />
      </Routes>
    </>
  );
};
export default Layout;
