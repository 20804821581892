import React from 'react';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';

const AppFAQ = ({ children }) => {
  return (
    <Container fluid>
      <div className="landing-gutter">
        <h1 className="landing-header">FAQ</h1>
        {children}
      </div>
    </Container>
  );
};

const Section = ({ children, title }) => {
  return (
    <>
      <h3
        className="faq-section-title"
        style={{ fontWeight: 600, fontSize: 26 }}
      >
        {title}
      </h3>
      {children}
    </>
  );
};

const Question = ({ children }) => {
  return <p className="faq-question text-red">{children}</p>;
};

const Answer = ({ children }) => {
  return <p className="faq-answer">{children}</p>;
};

AppFAQ.Section = Section;
AppFAQ.Question = Question;
AppFAQ.Answer = Answer;

AppFAQ.propTypes = {
  children: PropTypes.node.isRequired
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
};

Question.propTypes = {
  children: PropTypes.string.isRequired
};

Answer.propTypes = {
  children: PropTypes.string.isRequired
};

export default AppFAQ;
