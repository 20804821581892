import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getUTMUrl } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

const AppPricing = ({
  children,
  currency,
  setCurrency,
  dropdown = true,
  buttonPos = 'center'
}) => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash == '#pricing') {
      document.getElementById('pricing').scrollIntoView();
    }
  }, [location]);

  return (
    <Container fluid>
      <div
        style={{
          position: 'absolute',
          top: 90,
          left: 0,
          height: '100%',
          visibility: 'hidden'
        }}
        id="pricing"
      >
        Pricing Anchor
      </div>

      <div className="landing-gutter">
        <h1 className="landing-header">
          Simple <span className="text-red">Pricing</span>
        </h1>
        {dropdown && (
          <div>
            <Form.Select
              value={currency}
              onChange={e => setCurrency(e.target.value)}
              style={{ width: 'fit-content', marginBottom: 12 }}
            >
              <option value="USD">Show price in USD ($)</option>
              <option value="GBP">Show price in GBP (£)</option>
              <option value="EUR">Show price in EUR (€)</option>
            </Form.Select>
          </div>
        )}
        <Row>{children}</Row>

        {buttonPos == 'center' && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Button
              className="ourapps-learnmore"
              style={{ alignSelf: 'center', marginTop: 48 }}
              variant="danger"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_BASE_URL}/signup`,
                  '_blank'
                );
              }}
            >
              Get Started For Free{' '}
              <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
            </Button>
          </div>
        )}

        {buttonPos == 'left' && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center'
            }}
          >
            <Button
              className="ourapps-learnmore"
              style={{ alignSelf: 'center', marginTop: 48 }}
              variant="danger"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_BASE_URL}/signup`,
                  '_blank'
                );
              }}
            >
              Get Started For Free{' '}
              <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
            </Button>
          </div>
        )}
      </div>
    </Container>
  );
};

const Price = ({ name, price, features, popular, currency, slider, type }) => {
  const [symbol, setSymbol] = useState('');

  useEffect(() => {
    switch (currency) {
      case 'USD':
        setSymbol('$');
        break;
      case 'EUR':
        setSymbol('€');
        break;
      case 'GBP':
        setSymbol('£');
        break;
    }
  }),
    [currency];

  if (slider == undefined) {
    slider == false;
  }

  const formatPrice = price => {
    price = price.toString();
    if (price.slice(-2) == '00') {
      return price.slice(0, -2);
    } else if (price == '0') {
      return price;
    } else {
      return price.slice(0, -2) + '.' + price.slice(-2);
    }
  };

  let priceStyle = {};
  if (slider) {
    priceStyle = {
      minHeight: 'unset',
      paddingBottom: 28
    };
  }

  if (slider) {
    return (
      <>
        <div
          className="price-popular"
          // style={{ visibility: popular ? 'visible' : 'hidden' }}
          style={{ display: 'none' }}
        >
          Most popular
        </div>
        <div className="price" style={priceStyle}>
          <h2 className="price-header" style={{ fontSize: '1.728rem' }}>
            {name}
          </h2>
          <div
            style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}
          >
            {/* {typeof price == 'number' && name != 'Enterprise' ? (
              <>
                <span className="price-symbol">{symbol}</span>
                <span className="price-amount">{formatPrice(price)}</span>
                {price !== 0 && type != 'additional-credit' && (
                  <span className="price-month">/month</span>
                )}
              </>
            ) : (
              <span className="price-amount">{price}</span>
            )} */}
            {name == 'Enterprise' && <span className="price-amount">Call</span>}
            {typeof price == 'number' && name != 'Enterprise' && (
              <>
                <span className="price-symbol">{symbol}</span>
                <span className="price-amount">{formatPrice(price)}</span>
                {price !== 0 && type != 'additional-credit' && (
                  <span className="price-month">/month</span>
                )}
              </>
            )}
          </div>
          <ul className="price-features">
            {features.map((feature, idx) => {
              return (
                <li key={idx}>{feature.replace('{currency} ', symbol)}</li>
              );
            })}
          </ul>
        </div>
      </>
    );
  }

  return (
    <>
      <Col xl={3} md={6}>
        <div
          className="price-popular"
          // style={{ visibility: popular ? 'visible' : 'hidden' }}
          style={{ display: 'none' }}
        >
          Most popular
        </div>
        <div className="price" style={priceStyle}>
          <h2 className="price-header" style={{ fontSize: '1.728rem' }}>
            {name}
          </h2>
          <div
            style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}
          >
            {typeof price == 'number' ? (
              <>
                <span className="price-symbol">{symbol}</span>
                <span className="price-amount">{formatPrice(price)}</span>
                {price !== 0 && <span className="price-month">/month</span>}
              </>
            ) : (
              <span className="price-amount">{price}</span>
            )}
          </div>
          <ul className="price-features">
            {features.map((feature, idx) => {
              return (
                <li key={idx}>{feature.replace('{currency} ', symbol)}</li>
              );
            })}
          </ul>
        </div>
      </Col>
    </>
  );
};

const Slider = ({ products, currency, title, style }) => {
  const [planSlider, setPlanSlider] = useState(0);

  products.forEach(p => {
    if (p.Name == 'Enterprise' && p.Price == 0) {
      let enterprise = p;
      products = products.filter(p => p.Name != 'Enterprise');
      products.push(enterprise);
    }
  });

  const product = products[planSlider];

  let productDesc;
  try {
    productDesc = JSON.parse(product.Description);
  } catch {
    productDesc = [product.Description];
  }

  return (
    <Card style={style}>
      <Row className="p-5">
        <Col lg={8}>
          <h3 className="pb-3">{title}</h3>
          {/* <p className="pb-3">
            How many email addresses would you like to validate each month?
          </p> */}
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Form.Range
              min={0}
              max={products.length - 1}
              step={1}
              onChange={e => {
                setPlanSlider(e.target.value);
              }}
              value={planSlider}
              className="plans-range"
              style={{ width: 'calc(100% - 37px)' }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {products.map((tier, idx) => {
              let requests = tier.Requests.toLocaleString();
              switch (tier.Name) {
                case 'Enterprise':
                  requests = '100,000+';
                  break;
                case 'Free Trial':
                  requests = 'Free Trial';
                  break;
                case 'Partner':
                  requests = 'Partner';
                  break;
              }

              let current = '';
              if (tier.Requests == products[planSlider].Requests) {
                current = '-current';
              }

              return (
                <span
                  key={idx}
                  style={{ textAlign: 'center' }}
                  className={`price-slider-label${current}`}
                >
                  {requests}
                </span>
              );
            })}
          </div>
        </Col>
        <Col lg={4}>
          <AppPricing.Price
            name={product.Name}
            currency={currency}
            price={product.Price}
            features={productDesc}
            slider={true}
            type={product.Type}
          />
        </Col>
      </Row>
    </Card>
  );
};

AppPricing.Price = Price;
AppPricing.Slider = Slider;

AppPricing.propTypes = {
  children: PropTypes.node,
  currency: PropTypes.string,
  setCurrency: PropTypes.func,
  dropdown: PropTypes.bool,
  buttonPos: PropTypes.string
};

Price.propTypes = {
  // children: PropTypes.node,
  name: PropTypes.string,
  price: PropTypes.any,
  features: PropTypes.array,
  popular: PropTypes.bool,
  currency: PropTypes.string,
  slider: PropTypes.bool,
  type: PropTypes.string
};

Slider.propTypes = {
  products: PropTypes.array,
  currency: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.object
};

export default AppPricing;
