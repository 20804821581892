import React, { useState, useEffect } from 'react';
import NavbarStandard from 'components/landing/NavbarStandard';
import AppHero from 'components/landing/app-landing/AppHero';
import AppFeature from 'components/landing/app-landing/AppFeature';
import AppPricing from 'components/landing/app-landing/AppPricing';
import Footer from 'components/landing/Footer';
import { Container } from 'react-bootstrap';
import Helmet from 'react-helmet';
import AppFAQ from 'components/landing/app-landing/AppFAQ';
import { trackHubSpotPageView } from 'helpers/hubspot';

const ActivityM8LandingPage = () => {
  const [products, setProducts] = useState([]);
  const [currency, setCurrency] = useState('USD');

  const getSymbol = currency => {
    switch (currency) {
      case 'USD':
        return '$';
      case 'EUR':
        return '€';
      case 'GBP':
        return '£';
    }
  };

  useEffect(() => {
    if (navigator.userAgent === 'ReactSnap') {
      return;
    }

    trackHubSpotPageView(
      'Pacific Marketing Labs - Activity-M8',
      '/activity-m8'
    );

    const hsMeetingScript = document.createElement('script');

    hsMeetingScript.src =
      'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    hsMeetingScript.async = true;

    document.body.appendChild(hsMeetingScript);

    return () => {
      document.body.removeChild(hsMeetingScript);
    };
  }, []);

  const isSnap = navigator.userAgent.toLowerCase().includes('reactsnap');

  // useEffect(() => {
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_APP_URL}/subscriptions/get-products`,
  //       {
  //         app: 'activitym8',
  //         currency: currency
  //       },
  //       {
  //         headers: {
  //           'Access-Control-Allow-Origin': process.env.REACT_APP_APP_URL
  //         }
  //       }
  //     )
  //     .then(res => {
  //       setProducts(res.data);
  //     })
  //     .catch(err => {
  //       // console.log(err);
  //     });
  // }, [currency]);

  return (
    <>
      <Helmet>
        <title>Pacific Marketing Labs - Activity-M8</title>
        <meta
          name="description"
          content="Activity-M8 for HubSpot summarises your contact engagement into a single graphical view, so your marketing and sales teams can focus on the right prospects."
        />
        {/* <meta
          name="keywords"
          content="Valid8, HubSpot email validation, Email validation HubSpot, HubSpot email verification, How to check email verify HubSpot, HubSpot email verification tool, HubSpot email authenticator, HubSpot email checker"
        /> */}
      </Helmet>
      <div className="landing-page">
        <NavbarStandard />
        <AppHero>
          <AppHero.Description
            videourl="https://www.youtube.com/embed/uLDGPxNRMWI"
            title="Activity-M8"
            columns={[6, 6]}
            certified={false}
          >
            <p className="apphero-description text-black">
              Activity-M8 summarises your contact engagement into a single
              graphical view, so your marketing and sales teams can focus on the
              right prospects by understanding:
            </p>
            <ul>
              <li>
                Is your contact engaging with your marketing and sales
                activities?
              </li>
              <li>Is your contact becoming more or less engaged? </li>
              <li>
                Which activities and channels does your contact prefer to engage
                with?
              </li>
            </ul>
          </AppHero.Description>
        </AppHero>
        <AppFeature bgcolor="blue" anchor={true}>
          <AppFeature.Title>
            <span className="text-yellow">Instant</span>
            <span className="text-white"> Contact Activity Summary</span>
          </AppFeature.Title>
          <AppFeature.Content
            imgpos="S"
            imgurl="/images/landingpages/activitym8_00.png"
          >
            <p className="appfeature-description text-white">
              HubSpot is awesome at keeping track of engagement activities with
              your prospects but there’s no at-a-glance summary to immediately
              understand if a prospect is interacting with your marketing or
              sales efforts.
              <br />
              <br />
              Activity-M8 sits right inside HubSpot on the Contact record. With
              one click, Activity-M8 creates an instant summary of the last 30
              days activity engagement with the contact so you can immediately
              understand how and how often your contact is engaging with
              activities.
              <br />
              <br />
              Use Activity-M8 to know when a prospect is ready to reach out to.
            </p>
          </AppFeature.Content>
        </AppFeature>
        <AppPricing
          currency={currency}
          setCurrency={setCurrency}
          dropdown={false}
          buttonPos={'left'}
        >
          <p className="appfeature-description">
            Activity-M8 for contacts is free to use.
          </p>
        </AppPricing>
        <AppFAQ>
          <AppFAQ.Section title="General">
            <AppFAQ.Question>What is Activity-M8?</AppFAQ.Question>
            <AppFAQ.Answer>
              Activity-M8 is a HubSpot app that plugs straight into your HubSpot
              portal. It adds a card to your Contact record screen so you can
              see an immediate summary of activities that your contact has
              engaged with, including email opens and clicks, page views,
              conversations sent, forms submitted, calls, meetings, SMS,
              LinkedIn and WhatsApp.
            </AppFAQ.Answer>
            <AppFAQ.Question>Who runs Activity-M8?</AppFAQ.Question>
            <AppFAQ.Answer>
              Activity-M8 is provided and managed by Pacific Marketing Labs Ltd,
              Jordans Courtyard, 8 Upper High Street, Thame, Oxfordshire, United
              Kingdom, OX9 3ER
            </AppFAQ.Answer>
          </AppFAQ.Section>
          <AppFAQ.Section title="Data Sources">
            <AppFAQ.Question>
              How accurate is Activity-M8 and where does the data come from?
            </AppFAQ.Question>
            <AppFAQ.Answer>
              The data comes directly from your HubSpot portal, Activity-M8 is
              simply summarising specific activities recorded for any given
              contact.
            </AppFAQ.Answer>
          </AppFAQ.Section>
          <AppFAQ.Section title="Getting Started">
            <AppFAQ.Question>How do I sign up for Activity-M8?</AppFAQ.Question>
            <AppFAQ.Answer>
              Signing up for Activity-M8 is simple, and free to get started. If
              you are already using one of our apps and have a Pacific Marketing
              Labs account, please{' '}
              <a
                href="https://app.pacificmarketinglabs.io/login"
                rel="noreferrer"
                target="_blank"
                className="text-red"
              >
                login
              </a>{' '}
              then head to either your Valid8 or Currency-R8 dashboard. From
              here, click the big red 'Install App' button to the bottom left of
              your screen which will take you through to the app install page.
              If you are new to Pacific Marketing Labs, please{' '}
              <a
                href="https://www.pacificmarketinglabs.io/signup"
                target="_blank"
                rel="noreferrer"
                className="text-red"
              >
                create your account
              </a>{' '}
              first, before attempting to install the app. For more guidance on
              installing Activity-M8, please watch this{' '}
              <a
                href="https://www.youtube.com/watch?v=jB30-gQ5FDc"
                target="_blank"
                rel="noreferrer"
                className="text-red"
              >
                video
              </a>
              .
            </AppFAQ.Answer>
            <AppFAQ.Question>
              How do I get the Activity-M8 app on my HubSpot portal?
            </AppFAQ.Question>
            <AppFAQ.Answer>
              Once you've signed up and connected Pacific Marketing Labs to your
              HubSpot portal, installing Activity-M8 to your HubSpot portal is
              very straight forward, you simply click the install button, and
              our software will take care of the rest. For more guidance on
              installing Activity-M8, please watch this{' '}
              <a
                href="https://www.youtube.com/watch?v=jB30-gQ5FDc"
                target="_blank"
                rel="noreferrer"
                className="text-red"
              >
                video
              </a>
              .
            </AppFAQ.Answer>
          </AppFAQ.Section>
          <AppFAQ.Section title="Plans & Pricing">
            <AppFAQ.Question>How much does Activity-M8 cost?</AppFAQ.Question>
            <AppFAQ.Answer>
              Activity-M8 for contacts is free to use.
            </AppFAQ.Answer>
            <AppFAQ.Question>
              Do I need a credit card for the free version?
            </AppFAQ.Question>
            <AppFAQ.Answer>
              No, you can sign up and start using Activity-M8 for contacts
              immediately without providing any credit card information.
            </AppFAQ.Answer>
          </AppFAQ.Section>
        </AppFAQ>
        <Container fluid className="bg-grey10">
          <div
            className="meetings-iframe-container"
            data-src="https://meetings.hubspot.com/justinshardlow/activity-m8-demo?embed=true"
          ></div>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default ActivityM8LandingPage;
