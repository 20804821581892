import hscertificationbadge from 'assets/img/landingpage/hscertificationbadge.png';
import App from 'components/landing/App.js';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const OurApps = () => {
  return (
    <Container fluid className="bg-blue">
      <div className="landing-gutter">
        <h2 className="ourapps-header" id="ourapps-header">
          Our Apps
        </h2>
        <Row>
          <Col xl={4}>
            <App path="/valid8">
              <App.Title>
                Valid<span className="text-red">8</span>
                <img
                  style={{
                    height: '100%',
                    display: 'inline-block',
                    width: 70,
                    marginLeft: 10
                  }}
                  src={hscertificationbadge}
                />
              </App.Title>
              <App.SubTitle>Real-time email verification</App.SubTitle>
              <App.Description>
                <p className="ourapps-description">
                  Valid8 provides real-time email validation for your HubSpot
                  contacts so you can:
                </p>
                <ul className="ourapps-description">
                  <li>
                    Instantly verify if your HubSpot contacts' email addresses
                    are valid
                  </li>
                  <li>Ensure your Marketing Contacts are valid</li>
                  <li>
                    Optimise your email campaign delivery rates and sender
                    reputation
                  </li>
                  <li>
                    Identify bogus or incorrect form submissions and typos
                  </li>
                  <li>
                    Easily include email validation as part of any HubSpot
                    workflow
                  </li>
                  <li>
                    Check any individual contact's email address with just one
                    click
                  </li>
                </ul>
                <br></br>
                <br />
              </App.Description>
            </App>
          </Col>
          <Col xl={4}>
            <App path="/currency-r8">
              <App.Title>
                Currency-R<span className="text-red">8</span>
                <img
                  style={{
                    height: '70',
                    display: 'inline-block',
                    width: 70,
                    marginLeft: 10
                  }}
                  src={hscertificationbadge}
                />
              </App.Title>
              <App.SubTitle>Real-time currency conversion</App.SubTitle>
              <App.Description>
                <p className="ourapps-description">
                  Comprehensive currency conversion certified app for HubSpot:
                </p>
                <ul className="ourapps-description">
                  <li>
                    Schedule automatic daily, weekly or monthly exchange rate
                    updates of all your HubSpot currencies
                  </li>
                  <li>
                    Instantly lookup the value of a deal in 150+ currencies
                    using real-time exchange rates
                  </li>
                  <li>Auto-update deal and line items to new currency value</li>
                  <li>Automate currency conversion within HubSpot workflows</li>
                </ul>
              </App.Description>
            </App>
          </Col>
          <Col xl={4}>
            <App path="/activity-m8">
              <App.Title>
                Activity-M<span className="text-red">8</span>
                <img
                  style={{
                    height: '70',
                    display: 'inline-block',
                    width: 70,
                    marginLeft: 10,
                    visibility: 'hidden'
                  }}
                  src={hscertificationbadge}
                />
              </App.Title>
              <App.SubTitle>Instant contact activity summary</App.SubTitle>
              <App.Description>
                <p className="ourapps-description">
                  Activity-M8 summarises your contact engagement into a single
                  graphical view, so your marketing and sales teams can focus on
                  the right prospects by understanding:
                </p>
                <ul className="ourapps-description">
                  <li>
                    Is your contact engaging with your marketing and sales
                    activities?
                  </li>
                  <li>Is your contact becoming more or less engaged?</li>
                  <li>
                    Which activities and channels does your contact prefer to
                    engage with?
                  </li>
                </ul>
                <br></br>
                <br />
              </App.Description>
            </App>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default OurApps;
