import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Alert,
  InputGroup
} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import AnimateCC from 'react-adobe-animate';
import { trackHubSpotPageView } from 'helpers/hubspot';
import NavbarStandard from 'components/landing/NavbarStandard';
import Footer from 'components/landing/Footer';

//  * check for string@string.string
//  * this is enough as we will verify email later
const emailRegex = new RegExp(/^\S+@\S+\.\S+$/);
//  * Checks for 1 letter, 1 number, 12 characters and a special char
const passwordRegex = new RegExp(
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{12,}$/
);

const Signup = () => {
  // * Setup States for all input fields and their validity
  const [firstName, setFirstName] = useState('');
  const [firstNameisInvalid, setFirstNameIsInvalid] = useState(null);
  const [lastName, setLastName] = useState('');
  const [lastNameIsInvalid, setLastNameIsInvalid] = useState(null);
  const [email, setEmail] = useState('');
  const [emailIsInvalid, setEmailIsInvalid] = useState(null);
  const [password, setPassword] = useState('');
  const [passwordIsInvalid, setPasswordIsInvalid] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordIsInvalid, setConfirmPasswordIsInvalid] =
    useState(null);
  const [termsChecked, setTermsChecked] = useState(false);
  const [termsInvalid, setTermsInvalid] = useState(null);

  // * Other States
  const [loading, setLoading] = useState(false);
  const [emailTaken, setEmailTaken] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  //  * Give immedidate feedback on password and email fields
  useEffect(() => {
    if (email !== '' && !emailRegex.test(email)) {
      setEmailIsInvalid(true);
      emailIsInvalid;
    } else if (email === '') {
      setEmailIsInvalid(null);
    } else {
      setEmailIsInvalid(false);
    }

    if (password !== '' && !passwordRegex.test(password)) {
      setPasswordIsInvalid(true);
    } else if (password == '') {
      setPasswordIsInvalid(null);
    } else {
      setPasswordIsInvalid(false);
    }

    if (confirmPassword !== '' && confirmPassword !== password) {
      setConfirmPasswordIsInvalid(true);
    } else if (confirmPassword == '') {
      setConfirmPasswordIsInvalid(null);
    } else {
      setConfirmPasswordIsInvalid(false);
    }
  }, [email, password, confirmPassword]);

  useEffect(() => {
    trackHubSpotPageView('Pacific Marketing Labs - Partner', '/partner');
  }, []);

  const checkFields = () => {
    let fieldsAreValid = true;
    if (firstName == '') {
      fieldsAreValid = false;
      setFirstNameIsInvalid(true);
    } else {
      setFirstNameIsInvalid(false);
    }
    if (lastName == '') {
      fieldsAreValid = false;
      setLastNameIsInvalid(true);
    } else {
      setLastNameIsInvalid(false);
    }
    if (email == '' || !emailRegex.test(email)) {
      fieldsAreValid = false;
      setEmailIsInvalid(true);
    } else {
      setEmailIsInvalid(false);
    }
    if (password == '' || !passwordRegex.test(password)) {
      fieldsAreValid = false;
      setPasswordIsInvalid(true);
    } else {
      setPasswordIsInvalid(false);
    }
    if (confirmPassword == '' || confirmPassword !== password) {
      fieldsAreValid = false;
      setConfirmPasswordIsInvalid(true);
    } else {
      setConfirmPasswordIsInvalid(false);
    }
    if (termsChecked == false) {
      fieldsAreValid = false;
      setTermsInvalid(true);
    } else {
      setTermsInvalid(false);
    }
    return fieldsAreValid;
  };

  const onSubmit = async () => {
    setEmailTaken(false);

    if (checkFields() == true) {
      setLoading(true);
      let data = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        utms: JSON.parse(localStorage.getItem('utms'))
      };

      let result = await axios
        .post(`${process.env.REACT_APP_APP_URL}/create-account`, data)
        .then(res => {
          return res.data;
        })
        .catch(err => console.log(err.data));

      setLoading(false);
      if (result === 'taken') {
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setEmailTaken(true);
        setTermsChecked(false);
      } else {
        localStorage.setItem('PMLAdmin', 'null');
        localStorage.setItem('fname', data.firstName);
        localStorage.setItem('lname', data.lastName);
        axios
          .post(`${process.env.REACT_APP_APP_URL}/auth/get-user-token`, {
            email: email,
            password: password
          })
          .then(
            res =>
              (window.location = `${process.env.REACT_APP_APP_URL}/portals?token=${res.data.token}`)
            // console.dir(res)
            // alert('Account created successfully')
          );
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Pacific Marketing Labs - Signup</title>
      </Helmet>
      <div className="landing-page">
        <NavbarStandard />
        <div
          className="landing-gutter"
          style={{
            paddingTop: 90,
            minHeight: '50vh',
            maxWidth: 'calc(100vw-144px)',
            backgroundColor: '#fff'
          }}
        >
          <Row>
            <Col md={6}>
              <h1 className="landing-header">
                Get <span className="text-red">Started</span>
              </h1>
              <p className="apphero-description text-black">
                Create your Pacific Marketing Labs account using the form to get
                started...
              </p>
            </Col>
            <Col md={6}>
              {emailTaken && (
                <Alert
                  variant="danger"
                  onClose={() => setEmailTaken(false)}
                  dismissible
                >
                  <p className="m-0">Email address already taken.</p>
                </Alert>
              )}
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                      isInvalid={firstNameisInvalid}
                    />
                    <Form.Control.Feedback type="invalid">
                      First Name cannot be empty.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                      isInvalid={lastNameIsInvalid}
                    />
                    <Form.Control.Feedback type="invalid">
                      Last Name cannot be empty.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  isInvalid={emailIsInvalid}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email address.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    isInvalid={passwordIsInvalid}
                  />
                  <InputGroup.Text>
                    <FontAwesomeIcon
                      icon={showPassword ? faEyeSlash : faEye}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </InputGroup.Text>
                </InputGroup>
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: passwordIsInvalid ? 'block' : 'none' }}
                >
                  Password must contain at least 1 uppercase, 1 lowercase, 1
                  number, 1 special character(@$!%*#?&) and be 12 characters
                  long.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Confirm Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                    isInvalid={confirmPasswordIsInvalid}
                  />
                  <InputGroup.Text>
                    <FontAwesomeIcon
                      icon={showConfirmPassword ? faEyeSlash : faEye}
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    />
                  </InputGroup.Text>
                </InputGroup>
                <Form.Control.Feedback
                  type="invalid"
                  style={{
                    display: confirmPasswordIsInvalid ? 'block' : 'none'
                  }}
                >
                  Passwords must match.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Check type="checkbox" id="defaultCheckbox">
                  <Form.Check.Input
                    type="checkbox"
                    checked={termsChecked}
                    onClick={() => {
                      setTermsChecked(!termsChecked);
                    }}
                    isInvalid={termsInvalid}
                  />
                  <Form.Check.Label>
                    I agree to Pacific Marketing Labs'{' '}
                    <a
                      href="https://www.pacificmarketinglabs.io/Terms-Conditions-Pacific-Marketing-Labs-Jan-23.pdf"
                      style={{ textDecoration: 'underline' }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms & Conditions
                    </a>{' '}
                    of use.
                  </Form.Check.Label>
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ marginLeft: '-2em', marginTop: '-6px' }}
                  >
                    Please read and accept terms and conditions
                  </Form.Control.Feedback>
                </Form.Check>
              </Form.Group>
              <Button
                variant="danger"
                disabled={loading}
                className="btn-block"
                onClick={onSubmit}
              >
                {loading ? 'Loading...' : 'Create Account'}
              </Button>
              {/* <hr />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center'
                }}
              >
                <p style={{ margin: 0, paddingRight: 8 }}>
                  Already have an account?
                </p>
                <a
                  className="nav-link"
                  style={{ cursor: 'pointer', padding: 0 }}
                  href={`${process.env.REACT_APP_APP_URL}/login`}
                >
                  Login
                </a>
              </div> */}
            </Col>
          </Row>
        </div>

        <Footer />
      </div>

      {/* <div className="centerDiv">
        <div className="centerDivLogo" style={{ width: 150, height: 150 }}>
          <AnimateCC animationName="LabsLogoBlue" paused={false} />
        </div>
        <Card className="p-32" style={{ maxWidth: 600 }}>
          <Card.Body>
            <h3>Create your account</h3>
            {emailTaken && (
              <Alert
                variant="danger"
                onClose={() => setEmailTaken(false)}
                dismissible
              >
                <p className="m-0">Email address already taken.</p>
              </Alert>
            )}
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    isInvalid={firstNameisInvalid}
                  />
                  <Form.Control.Feedback type="invalid">
                    First Name cannot be empty.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                    isInvalid={lastNameIsInvalid}
                  />
                  <Form.Control.Feedback type="invalid">
                    Last Name cannot be empty.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                isInvalid={emailIsInvalid}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid email address.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <InputGroup>
                <Form.Control
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  isInvalid={passwordIsInvalid}
                />
                <InputGroup.Text>
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </InputGroup.Text>
              </InputGroup>
              <Form.Control.Feedback
                type="invalid"
                style={{ display: passwordIsInvalid ? 'block' : 'none' }}
              >
                Password must contain at least 1 uppercase, 1 lowercase, 1
                number, 1 special character(@$!%*#?&) and be 12 characters long.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Confirm Password</Form.Label>
              <InputGroup>
                <Form.Control
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                  isInvalid={confirmPasswordIsInvalid}
                />
                <InputGroup.Text>
                  <FontAwesomeIcon
                    icon={showConfirmPassword ? faEyeSlash : faEye}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                </InputGroup.Text>
              </InputGroup>
              <Form.Control.Feedback
                type="invalid"
                style={{ display: confirmPasswordIsInvalid ? 'block' : 'none' }}
              >
                Passwords must match.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check type="checkbox" id="defaultCheckbox">
                <Form.Check.Input
                  type="checkbox"
                  checked={termsChecked}
                  onClick={() => {
                    setTermsChecked(!termsChecked);
                  }}
                  isInvalid={termsInvalid}
                />
                <Form.Check.Label>
                  I agree to the{' '}
                  <a
                    href="https://www.pacificmarketinglabs.io/Terms-Conditions-Pacific-Marketing-Labs-Jan-23.pdf"
                    style={{ textDecoration: 'underline' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms and Conditions
                  </a>
                </Form.Check.Label>
                <Form.Control.Feedback
                  type="invalid"
                  style={{ marginLeft: '-2em', marginTop: '-6px' }}
                >
                  Please read and accept terms and conditions
                </Form.Control.Feedback>
              </Form.Check>
            </Form.Group>
            <Button
              variant="danger"
              disabled={loading}
              className="btn-block"
              onClick={onSubmit}
            >
              {loading ? 'Loading...' : 'Create Account'}
            </Button>
            <hr />

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              <p style={{ margin: 0, paddingRight: 8 }}>
                Already have an account?
              </p>
              <a
                className="nav-link"
                style={{ cursor: 'pointer', padding: 0 }}
                href={`${process.env.REACT_APP_APP_URL}/login`}
              >
                Login
              </a>
            </div>
          </Card.Body>
        </Card>
      </div> */}
    </>
  );
};

export default Signup;
