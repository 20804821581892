import React from 'react';
import { Container } from 'react-bootstrap';

const AboutPML = () => {
  return (
    <Container fluid>
      <div className="landing-gutter">
        <h1 className="landing-header">
          About <span className="text-red">Pacific Marketing Labs</span>
        </h1>
        <p className="aboutus-description text-bold">
          We're on a mission - to build a swiss army knife of incredibly useful
          utility apps for HubSpot.
        </p>
        <p className="aboutus-description">
          The ideas for our apps come from the real-world requirements of our
          clients. But, we're always keen to hear from other HubSpot users, so
          if there's an app you could do with, send us your idea - if we think
          the wider HubSpot user community would benefit, we'll build it!
        </p>
        <p className="aboutus-description">
          Sign up to receive our newsletter and be the first to hear about our
          latest releases as well as tips and tricks for getting the most from
          our apps.
        </p>
        <div id="newsletterForm"></div>
      </div>
    </Container>
  );
};

export default AboutPML;
