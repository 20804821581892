import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { trackHubSpotPageView } from 'helpers/hubspot';
import { Container } from 'react-bootstrap';

import NavbarStandard from 'components/landing/NavbarStandard';
import Footer from 'components/landing/Footer';

const Contact = () => {
  const isSnap = navigator.userAgent.toLowerCase().includes('reactsnap');

  useEffect(() => {
    if (isSnap) {
      return;
    }
    trackHubSpotPageView('Pacific Marketing Labs - Contact', '/contact');
    const hsMeetingScript = document.createElement('script');

    hsMeetingScript.src =
      'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    hsMeetingScript.async = true;

    document.body.appendChild(hsMeetingScript);

    return () => {
      document.body.removeChild(hsMeetingScript);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: process.env.REACT_APP_PORTAL_ID,
          formId: process.env.REACT_APP_CONTACTUS_FORM,
          target: '#contactUsForm'
        });
      }
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Pacific Marketing Labs - Contact</title>
      </Helmet>
      <div className="landing-page">
        <NavbarStandard />
        <div
          className="landing-gutter"
          style={{
            minHeight: '50vh',
            maxWidth: 'calc(100vw-144vw)',
            backgroundColor: '#fff'
          }}
        >
          <h1 className="landing-header">
            Contact <span className="text-red">Us</span>
          </h1>
          <p className="apphero-description">
            Please use the form below to contact us.
          </p>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              marginTop: '-40px'
            }}
          >
            <div id="contactUsForm"></div>
          </div>
        </div>
        <Container fluid className="bg-grey10">
          <div
            className="meetings-iframe-container"
            data-src="https://meetings.hubspot.com/justinshardlow/pacific-marketing-labs-apps-demo?embed=true"
          ></div>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default Contact;
