import React from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
// import swissknife from 'assets/img/landingpage/swissknife.png';
import { useNavigate } from 'react-router-dom';
import AnimateCC from 'react-adobe-animate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { getUTMUrl } from 'helpers/utils';

const Hero = () => {
  const navigate = useNavigate();
  return (
    <Container fluid>
      <div className="landing-gutter">
        <Row>
          <Col xl={7}>
            <h2 className="hero-subtitle">we're on a mission to create...</h2>
            <h1 className="landing-header">
              a <span className="text-red">swiss army knife</span> of incredibly
              useful utility apps <span className="text-red">for hubspot</span>.
            </h1>
            <Button
              variant="danger"
              className="ourapps-learnmore"
              onClick={() => {
                // document.getElementById('ourapps-header').scrollIntoView();
                window.open(
                  `${process.env.REACT_APP_BASE_URL}/signup`,
                  '_blank'
                );
              }}
            >
              Get Started For Free{' '}
              <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
            </Button>
          </Col>
          <Col xl={5}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              {/* <img src={swissknife} className="hero-swissknife" /> */}
              {/* <KnifeAnimation /> */}
              <div className="hero-swissknife" style={{ overflow: 'hidden' }}>
                <AnimateCC animationName="Knifeweb" paused={false} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Hero;
