import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { getUTMUrl } from 'helpers/utils';
import hscertificationbadge from 'assets/img/landingpage/hscertificationbadge.png';

const AppHero = ({ children }) => {
  return (
    <Container fluid>
      <div className="landing-gutter">{children}</div>
    </Container>
  );
};

const Description = ({
  children,
  imgurl,
  videourl,
  title,
  columns,
  certified
}) => {
  return (
    <Row>
      <Col xl={columns[0]}>
        <h1 className="landing-header">
          {title.slice(0, -1)}
          <span className="text-red">{title.slice(-1)}</span>
          {certified && (
            <img
              style={{
                height: '100%',
                display: 'inline-block',
                width: 70,
                marginLeft: 10
              }}
              src={hscertificationbadge}
            />
          )}
        </h1>
        <div className="apphero-description">{children}</div>
        <Button
          className="ourapps-learnmore"
          variant="danger"
          onClick={() => {
            // document.getElementById('findoutmoreanchor').scrollIntoView();
            window.open(`${process.env.REACT_APP_BASE_URL}/signup`, '_blank');
          }}
        >
          Get Started For Free{' '}
          <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
        </Button>
      </Col>
      <Col xl={columns[1]}>
        {videourl && (
          <>
            <iframe
              src={videourl}
              title="Pacific Marketing Labs"
              frameBorder="0"
              className="react-player"
              allowFullScreen
            ></iframe>
          </>
        )}
        {imgurl && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              height: '100%'
            }}
          >
            <img src={imgurl} className="apphero-img" />
          </div>
        )}
      </Col>
    </Row>
  );
};

AppHero.Description = Description;

AppHero.propTypes = {
  children: PropTypes.any.isRequired
};

Description.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  videourl: PropTypes.string,
  imgurl: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.number),
  certified: PropTypes.bool
};

export default AppHero;
