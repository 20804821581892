import React from 'react';
import { Container } from 'react-bootstrap';

const RequestApp = () => {
  return (
    <Container fluid>
      <div className="landing-gutter">
        <h1 className="landing-header">
          Need an <span className="text-red">app</span>?
        </h1>
        <p className="requestapp-text">
          Got a requirement for an app but don't know where to start? Send us
          your idea and if we think the wider HubSpot user community would
          benefit, we'll even build it as a product! Need something more bespoke
          to your business - no problem, we undertake bespoke projects as well.
        </p>
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center'
          }}
        >
          <div id="requestAppForm"></div>
        </div>
      </div>
    </Container>
  );
};

export default RequestApp;
