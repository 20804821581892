import React, { useEffect } from 'react';
import Hero from 'components/landing/Hero';
import NavbarStandard from 'components/landing/NavbarStandard';
import OurApps from 'components/landing/OurApps';
import AboutPML from 'components/landing/AboutPML';
import Customers from 'components/landing/Customers';
import RequestApp from 'components/landing/RequestApp';
import Footer from 'components/landing/Footer';
import Helmet from 'react-helmet';
import { trackHubSpotPageView } from 'helpers/hubspot';
import { Container } from 'react-bootstrap';
import Testimonials from 'components/landing/Testimonials';
import Testimonial from 'components/landing/Testimonial';

const Landing = () => {
  const isSnap = navigator.userAgent.toLowerCase().includes('reactsnap');

  useEffect(() => {
    if (isSnap) {
      return;
    }
    trackHubSpotPageView('Pacific Marketing Labs', '/');
    const hsMeetingScript = document.createElement('script');

    hsMeetingScript.src =
      'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    hsMeetingScript.async = true;

    document.body.appendChild(hsMeetingScript);

    return () => {
      document.body.removeChild(hsMeetingScript);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: process.env.REACT_APP_PORTAL_ID,
          formId: process.env.REACT_APP_NEWSLETTER_FORM,
          target: '#newsletterForm'
        });
        window.hbspt.forms.create({
          portalId: process.env.REACT_APP_PORTAL_ID,
          formId: process.env.REACT_APP_REQUESTAPP_FORM,
          target: '#requestAppForm'
        });
      }
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Pacific Marketing Labs</title>
        <meta
          name="description"
          content="As a HubSpot Gold partner, we're on a mission - to build a swiss army knife of incredibly useful utility apps for HubSpot."
        />
        <meta
          name="keywords"
          content="Hubspot, HubSpot App integration, HubSpot integrations"
        />
      </Helmet>
      <div className="landing-page">
        <NavbarStandard />
        <Hero />
        <OurApps />
        <AboutPML />
        <Testimonials bgcolor="bg-grey10">
          <Testimonial>
            <Testimonial.Quote>
              We're loving the Valid8 integration. Being able to check the
              validity of contacts in real time has improved our email health
              score and sender reputation and made it much easier to keep a
              clean list of marketing contacts!
            </Testimonial.Quote>
            <Testimonial.Name>Andy King</Testimonial.Name>
            <Testimonial.Company>
              Marketing Officer, The Social Change Agency
            </Testimonial.Company>
          </Testimonial>
          <Testimonial>
            <Testimonial.Quote>
              The whole team is very helpful and they won't rest until they
              resolve all the queries that you might have for them. The
              Currency-R8 integration is amazing in terms of what is providing.
            </Testimonial.Quote>
            <Testimonial.Name>Mario Todorovski</Testimonial.Name>
            <Testimonial.Company>
              Finance & Office Manager, Pabau
            </Testimonial.Company>
          </Testimonial>
          <Testimonial>
            <Testimonial.Quote>
              Valid8 gives us direct insight into the quality of our contact
              data, and is invaluable in helping us manage our marketing
              contacts by avoiding sending emails to bad or incorrect email
              addresses
            </Testimonial.Quote>
            <Testimonial.Name>Rob Sharpe</Testimonial.Name>
            <Testimonial.Company>
              Head of Digital Transformation, Outward Bound Trust
            </Testimonial.Company>
          </Testimonial>
          <Testimonial>
            <Testimonial.Quote>
              Currency-R8 does what it says on the tin. Ensures accurate values
              in our open pipeline and forecast, rather than leaving it up to
              manual updates of FX rates on an ad-hoc basis. Absolute no-brainer
              if you deal in multiple territories.
            </Testimonial.Quote>
            <Testimonial.Name>Sam Statham</Testimonial.Name>
            <Testimonial.Company>
              RevOps Support, AutomatePro
            </Testimonial.Company>
          </Testimonial>
          <Testimonial>
            <Testimonial.Quote>
              Valid8 has significantly helped us, from catching bogus form
              submissions through to providing a simple way to instantly check
              manually added contacts, having Valid8 ensures our contact data is
              clean and reliable
            </Testimonial.Quote>
            <Testimonial.Name>Rachael Robson</Testimonial.Name>
            <Testimonial.Company>
              Head of Marketing, Tquila Automation
            </Testimonial.Company>
          </Testimonial>
          <Testimonial>
            <Testimonial.Quote>
              Currency-R8 is a great app for automating currency rate
              conversations. The product has been simple to use and setup. Not
              only has the integration worked seamlessly, we were especially
              pleased with their customer service and support throughout the
              process. This is an easy to use tool for currency conversion
              automations with truly caring people behind the scenes.
            </Testimonial.Quote>
            <Testimonial.Name>Rachel Thayer-Cooney</Testimonial.Name>
            <Testimonial.Company>
              Data & Analytics Manager, Joorney
            </Testimonial.Company>
          </Testimonial>
        </Testimonials>
        <RequestApp />
        {/* <Valid8 /> */}
        <Container fluid className="bg-grey10">
          <div
            className="meetings-iframe-container"
            data-src="https://meetings.hubspot.com/justinshardlow/pacific-marketing-labs-apps-demo?embed=true"
          ></div>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default Landing;
