import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import Customers from 'components/landing/Customers';
import Footer from 'components/landing/Footer';
import NavbarStandard from 'components/landing/NavbarStandard';
import Testimonial from 'components/landing/Testimonial';
import Testimonials from 'components/landing/Testimonials';
import AppFAQ from 'components/landing/app-landing/AppFAQ';
import AppFeature from 'components/landing/app-landing/AppFeature';
import AppHero from 'components/landing/app-landing/AppHero';
import AppPricing from 'components/landing/app-landing/AppPricing';
import { trackHubSpotPageView } from 'helpers/hubspot';
import React, { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Helmet from 'react-helmet';

const Valid8LandingPage = () => {
  const [products, setProducts] = useState([]);
  const [currency, setCurrency] = useState('USD');

  const getSymbol = currency => {
    switch (currency) {
      case 'USD':
        return '$';
      case 'EUR':
        return '€';
      case 'GBP':
        return '£';
    }
  };

  useEffect(() => {
    if (navigator.userAgent === 'ReactSnap') {
      return;
    }
    trackHubSpotPageView('Pacific Marketing Labs - Valid8', '/valid8');
    const hsMeetingScript = document.createElement('script');

    hsMeetingScript.src =
      'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    hsMeetingScript.async = true;

    document.body.appendChild(hsMeetingScript);

    return () => {
      document.body.removeChild(hsMeetingScript);
    };
  }, []);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_APP_URL}/subscriptions/get-products`,
        {
          app: 'valid8',
          currency: currency
        },
        {
          headers: {
            'Access-Control-Allow-Origin': process.env.REACT_APP_APP_URL
          }
        }
      )
      .then(res => {
        setProducts(res.data);
      })
      .catch(err => {
        // console.log(err);
      });
  }, [currency]);

  return (
    <>
      <Helmet>
        <title>Pacific Marketing Labs - Valid8</title>
        <meta
          name="description"
          content="Valid8 automatically checks if a contact's email address is valid when they are created on your HubSpot CRM."
        />
        <meta
          name="keywords"
          content="Valid8, HubSpot email validation, Email validation HubSpot, HubSpot email verification, How to check email verify HubSpot, HubSpot email verification tool, HubSpot email authenticator, HubSpot email checker"
        />
      </Helmet>
      <div className="landing-page">
        <NavbarStandard />
        <AppHero>
          <AppHero.Description
            videourl="https://www.youtube.com/embed/hWeQpV36FAc"
            title="Valid8"
            columns={[6, 6]}
            certified
          >
            <p className="apphero-title text-black">
              Real-time email validation app for HubSpot
            </p>
            <ul>
              <li>
                Instantly verify if your HubSpot contacts' email addresses are
                valid
              </li>
              <li>Ensure your Marketing Contacts are valid</li>
              <li>
                Optimise your email campaign delivery rates and sender
                reputation
              </li>
              <li>Identify bogus or incorrect form submissions and typos</li>
              <li>
                Easily include email validation as part of any HubSpot workflow
              </li>
              <li>
                Check any individual contact's email address with just one click
              </li>
            </ul>
          </AppHero.Description>
        </AppHero>
        {/* <AppFeature bgcolor="blue" anchor={true}>
          <AppFeature.Title>
            <span className="text-yellow">Automatically check</span>
            <span className="text-white"> New Contacts</span>
          </AppFeature.Title>
          <AppFeature.Content
            imgpos="S"
            imgurl="/images/landingpages/valid8_10.png"
          >
            <p className="appfeature-description text-white">
              Valid8 automatically verifies the email address of all new
              contacts, whether created manually, via a form submission or via
              booking a meeting.
              <br />
              <br />
              The results are stored in custom properties as well as logged to
              the contact's activity timeline for you to instantly access.
              <br />
              <br />
              Catch bogus form fills and typos before they impact your Marketing
              Contact numbers and sender reputation.
            </p>
          </AppFeature.Content>
        </AppFeature> */}
        <AppFeature bgcolor="blue" anchor={true}>
          <AppFeature.Title>
            <span className="text-yellow">Keep Your</span>
            <span className="text-white"> Contact Data Healthy</span>
          </AppFeature.Title>
          <AppFeature.Content
            imgpos="E"
            videourl="https://www.pacificmarketinglabs.io/Pacific%20Marketing%20Labs%20Using%20Valid8%20to%20verify%20ALL%20your%20HubSpot%20contacts%20email%20addresses.mp4"
          >
            <p className="appfeature-description text-white">
              Ensuring your contacts' email address is valid is a top priority
              for marketers, sales teams and customer success.
              <br />
              <br />
              With Valid8, you can instantly and easily check any (or all) of
              your contacts' email addresses using a single workflow action.
              <br />
              <br />
              No need to export lists to clean on a third party platform, Valid8
              brings this functionality inside HubSpot. Whether you're looking
              to run a one-time cleanse or regular checks, our HubSpot workflow
              plug-in keeps things simple.
              <br />
              <br />
              From keeping your contact data healthy, to catching bogus form
              fills and typos, Valid8 helps keep your marketing contact numbers
              under control and your sender reputation intact.
            </p>
          </AppFeature.Content>
        </AppFeature>
        <AppFeature bgcolor="grey75">
          <AppFeature.Title>
            <span className="text-yellow">Check</span>
            <span className="text-white"> On Demand</span>
          </AppFeature.Title>
          <AppFeature.Content
            imgpos="E"
            imgurl="/images/landingpages/valid8_20.png"
          >
            <p className="appfeature-description text-white">
              Need to check a contact's email address immediately? <br />
              <br />
              No problem. With Valid8, checking a contact's email address is
              just one click away.
              <br />
              <br />
              The Valid8 card sits right alongside the contact record, just hit
              'Run new validation' to get an instant verification of your
              contact's email address.
            </p>
            <Button
              className="ourapps-learnmore"
              style={{ marginTop: 16 }}
              variant="danger"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_BASE_URL}/signup`,
                  '_blank'
                );
              }}
            >
              Get Started For Free{' '}
              <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
            </Button>
          </AppFeature.Content>
        </AppFeature>
        <AppFeature bgcolor="grey25">
          <AppFeature.Title>
            <span>Use Valid8 In</span>
            <span className="text-red"> Workflows</span>
          </AppFeature.Title>
          <AppFeature.Content
            imgpos="E"
            imgurl="/images/landingpages/valid8_25.png"
            imgMaxWidth="800px"
          >
            <p className="appfeature-description">
              Valid8 email verification can be easily added to your HubSpot
              workflows.
              <br />
              <br />
              No need to configure anything, just drop Valid8 into your workflow
              then use the result which is stored to Valid8's custom Contact
              properties to determine next actions.
            </p>
            <Button
              className="ourapps-learnmore"
              style={{ marginTop: 16 }}
              variant="danger"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_BASE_URL}/signup`,
                  '_blank'
                );
              }}
            >
              Get Started For Free{' '}
              <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
            </Button>
          </AppFeature.Content>
        </AppFeature>
        <AppFeature bgcolor="grey50">
          <AppFeature.Title>
            <span className="text-yellow">Quarantine</span>
            <span className="text-white"> Bad Contacts</span>
          </AppFeature.Title>
          <AppFeature.Content
            imgpos="E"
            imgurl="/images/landingpages/valid8_30.jpg"
            imgMaxWidth="800px"
          >
            <p className="appfeature-description text-white">
              Easily build dynamic lists to capture invalid email addresses.
              <br />
              <br />
              Prevent bad contacts becoming paid for Marketing Contacts.
              <br />
              <br />
              Maintain your sender reputation, never send a bad email address
              again.
            </p>
            <Button
              className="ourapps-learnmore"
              style={{ marginTop: 16 }}
              variant="danger"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_BASE_URL}/signup`,
                  '_blank'
                );
              }}
            >
              Get Started For Free{' '}
              <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
            </Button>
          </AppFeature.Content>
        </AppFeature>
        <AppFeature bgcolor="grey25" gutterStyle={{ paddingBottom: '20px' }}>
          <AppFeature.Title>
            Dashboards &amp;
            <span className="text-red"> Reporting</span>
          </AppFeature.Title>
          <AppFeature.Content
            imgpos="E"
            imgurl="/images/landingpages/valid8_00.jpg"
            imgMaxWidth="800px"
          >
            <p className="appfeature-description">
              Valid8 includes a real-time dashboard so you can monitor contact
              email address health in real-time inside your Pacific Marketing
              Labs portal.
            </p>
            <br />
            <p className="appfeature-description">
              <ul>
                <li>Emails checked per day</li>
                <li>
                  Breakdown of all emails checked -&gt; Valid, Invalid,
                  Unverifiable
                </li>
                <li>Track checks and performance over time</li>
              </ul>
            </p>

            {/* <Button
              className="ourapps-learnmore"
              style={{ marginTop: 16 }}
              variant="danger"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_BASE_URL}/signup`,
                  '_blank'
                );
              }}
            >
              Get Started For Free{' '}
              <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
            </Button> */}
          </AppFeature.Content>
        </AppFeature>
        <AppFeature bgcolor="grey25" containerStyle={{ paddingTop: '40px' }}>
          {/* <AppFeature.Title>
            Dashboards &amp;
            <span className="text-red"> Reporting</span>
          </AppFeature.Title> */}
          <AppFeature.Content
            imgpos="E"
            imgurl="/images/landingpages/valid8_superd_dashboard.png"
            imgMaxWidth="800px"
          >
            {/* <br /> */}
            <p className="appfeature-description">
              Alternatively, monitor your contact email address health using a
              native HubSpot dashboard.
            </p>
            <br />
            {/* <p className="appfeature-description">
              We've teamed up with our friends at Supered to make installing
              this exclusive dashboard to your HubSpot portal a super simple
              process!
            </p> */}
            {/* <Button
              className="ourapps-learnmore"
              style={{ marginTop: 16 }}
              variant="danger"
              onClick={() => {
                window.open(
                  `https://www.supered.io/partners/valid8/`,
                  '_blank'
                );
              }}
            >
              Download you exclusive Dashboard{' '}
              <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
            </Button> */}
          </AppFeature.Content>
        </AppFeature>
        <Testimonials bgcolor="bg-grey10">
          <Testimonial>
            <Testimonial.Quote>
              We're loving the Valid8 integration. Being able to check the
              validity of contacts in real time has improved our email health
              score and sender reputation and made it much easier to keep a
              clean list of marketing contacts!
            </Testimonial.Quote>
            <Testimonial.Name>Andy King</Testimonial.Name>
            <Testimonial.Company>
              Marketing Officer, The Social Change Agency
            </Testimonial.Company>
          </Testimonial>
          <Testimonial>
            <Testimonial.Quote>
              Valid8 gives us direct insight into the quality of our contact
              data, and is invaluable in helping us manage our marketing
              contacts by avoiding sending emails to bad or incorrect email
              addresses
            </Testimonial.Quote>
            <Testimonial.Name>Rob Sharpe</Testimonial.Name>
            <Testimonial.Company>
              Head of Digital Transformation, Outward Bound Trust
            </Testimonial.Company>
          </Testimonial>
          <Testimonial>
            <Testimonial.Quote>
              Valid8 has significantly helped us, from catching bogus form
              submissions through to providing a simple way to instantly check
              manually added contacts, having Valid8 ensures our contact data is
              clean and reliable
            </Testimonial.Quote>
            <Testimonial.Name>Rachael Robson</Testimonial.Name>
            <Testimonial.Company>
              Head of Marketing, Tquila Automation
            </Testimonial.Company>
          </Testimonial>
        </Testimonials>
        <AppPricing currency={currency} setCurrency={setCurrency}>
          {products.length !== 0 && (
            <>
              {/* <AppPricing.Price
                name="Free Trial"
                currency={currency}
                price={
                  products.filter(product => product.Name === 'Free Trial')[0]
                    .Price
                }
                features={JSON.parse(
                  products.filter(product => product.Name === 'Free Trial')[0]
                    .Description
                )}
              />
              <AppPricing.Price
                name="Essential"
                currency={currency}
                price={
                  products.filter(product => product.Name === 'Essential')[0]
                    .Price
                }
                features={JSON.parse(
                  products.filter(product => product.Name === 'Essential')[0]
                    .Description
                )}
              />
              <AppPricing.Price
                name="Pro"
                currency={currency}
                price={
                  products.filter(product => product.Name === 'Pro')[0].Price
                }
                features={JSON.parse(
                  products.filter(product => product.Name === 'Pro')[0]
                    .Description
                )}
              />
              <AppPricing.Price
                name="Enterprise"
                price="Call"
                features={[
                  products.filter(product => product.Name === 'Enterprise')[0]
                    .Description
                ]}
              /> */}
              <AppPricing.Slider
                products={products
                  .filter(
                    p =>
                      (p.Type === 'subscription' || p.Type === 'trial') &&
                      p.Hidden == false &&
                      p.AccountID == null &&
                      p.Name !== 'Partner'
                  )
                  .sort((a, b) => parseFloat(a.Price) - parseFloat(b.Price))}
                currency={currency}
                title={'Monthly Plans'}
              />
              <AppPricing.Slider
                products={products
                  .filter(
                    p =>
                      p.Type === 'additional-credit' &&
                      p.Hidden == false &&
                      p.AccountID == null &&
                      p.Name !== 'Partner'
                  )
                  .sort((a, b) => parseFloat(a.Price) - parseFloat(b.Price))}
                currency={currency}
                title={'Booster Packs'}
                style={{ marginTop: '40px' }}
              />
            </>
          )}
        </AppPricing>
        <AppFAQ>
          <AppFAQ.Section title="General">
            <AppFAQ.Question>What is Valid8?</AppFAQ.Question>
            <AppFAQ.Answer>
              Valid8 is a HubSpot app that plugs straight into your HubSpot
              portal. It automatically verifies a contact's email address for
              each new contact added to your HubSpot CRM. Valid8 also adds a
              card to your HubSpot Contacts screen so you can run email address
              verifications on demand and adds a new action to workflows
              allowing you to bake email verification right into your HubSpot
              workflows.
            </AppFAQ.Answer>
            <AppFAQ.Question>Who runs Valid8?</AppFAQ.Question>
            <AppFAQ.Answer>
              Valid8 is provided and managed by Pacific Marketing Labs Ltd,
              Jordans Courtyard, 8 Upper High Street, Thame, Oxfordshire, United
              Kingdom, OX9 3ER
            </AppFAQ.Answer>
            <AppFAQ.Question>
              Can I run email verifications instantly, on demand?
            </AppFAQ.Question>
            <AppFAQ.Answer>
              Yes, absolutely you can, this is exactly what Valid8 is designed
              for. Just click the button on the Valid8 card on your Contacts
              screen to instantly verify a contact's email address.{' '}
            </AppFAQ.Answer>
            <AppFAQ.Question>
              Can I use Valid8 to add email verification to my workflow
              automation?
            </AppFAQ.Question>
            <AppFAQ.Answer>
              Yes, with any of our paid plans you can use Valid8 to provide
              email verification actions with a HubSpot workflow. Valid8 has
              been designed to work seamlessly with HubSpot's workflow engine.
              Just select Valid8 from the list of actions then let the app take
              care of the rest, your contact's email address will be
              automatically verified.
            </AppFAQ.Answer>
          </AppFAQ.Section>
          <AppFAQ.Section title="Getting Started">
            <AppFAQ.Question>How do I sign up for Valid8?</AppFAQ.Question>
            <AppFAQ.Answer>
              Signing up for Valid8 is simple, and free to get started. Register
              now for a trial version{' '}
              <a
                className="text-red"
                href={`${process.env.REACT_APP_BASE_URL}/signup`}
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </AppFAQ.Answer>
            <AppFAQ.Question>
              How do I get the Valid8 app onto my HubSpot portal?
            </AppFAQ.Question>
            <AppFAQ.Answer>
              Once you've signed up and connected Pacific Marketing Labs to your
              HubSpot portal, installing Valid8 to your HubSpot portal is very
              straight forward, you simply click the install button, and our
              software will take care of the rest.
            </AppFAQ.Answer>
          </AppFAQ.Section>
          <AppFAQ.Section title="Plans & Pricing">
            <AppFAQ.Question>
              Which plan is most suitable for me?
            </AppFAQ.Question>
            <AppFAQ.Answer>
              This will depend on your expected usage. All paid plans come with
              a number of bundled credits but also include provisions for
              'overuse' so your team can continue to use the app beyond your
              monthly bundled allowance. With Valid8, we also offer 'Booster
              Packs' which are bundles of additional credits that can be used
              within your current billing cycle (monthly or annual.) This can be
              useful if you want to run a one-time validation of your contacts'
              email addresses in bulk.
            </AppFAQ.Answer>
            <AppFAQ.Question>
              What is meant by a 'request credit'?
            </AppFAQ.Question>
            <AppFAQ.Answer>
              A 'request credit' is spent each time you request an email address
              to be verified. This includes automatic verification when a new
              contact is added to your HubSpot CRM, clicking the in-app button
              to verify a contact's email address on demand and when a workflow
              makes a request to verify a contact's email address.
            </AppFAQ.Answer>
            <AppFAQ.Question>
              How does the Professional plan work for 500+ requests?
            </AppFAQ.Question>
            <AppFAQ.Answer>
              Our Professional (Pro) plan includes 500 request credits per
              month. If you use these, the app will continue to run, and you
              will be charged per additional request. Please see our pricing for
              current charges.
            </AppFAQ.Answer>
            <AppFAQ.Question>How do I pay?</AppFAQ.Question>
            <AppFAQ.Answer>
              All payments are made by credit card on a recurring subscription.
              We offer the option of paying in US Dollar (USD), British Pound
              Sterling (GBP) or Euro (EUR). Your plan subscription will be
              charged to your card in advance on the first day of your plan each
              month. Overage charges (for Pro and Enterprise customers) will be
              charged retrospectively on the following first day of your monthly
              plan. For example, if you sign up for a Pro plan on the 15 August
              2022, you will be charged for the first month's subscription when
              you sign up. On the 15 September 2022 you will charged for the
              second month's subscription plus any overuse charges due for the
              period 15 August 2022 through to 14 September 2022 inclusive.
            </AppFAQ.Answer>
            <AppFAQ.Question>
              Do I need a credit card for the free version?
            </AppFAQ.Question>
            <AppFAQ.Answer>
              No, you can sign up and start using Valid8 immediately without
              providing any credit card information.
            </AppFAQ.Answer>
          </AppFAQ.Section>
        </AppFAQ>
        <Container fluid className="bg-grey10">
          <div
            className="meetings-iframe-container"
            data-src="https://meetings.hubspot.com/justinshardlow/valid8-demo?embed=true"
          ></div>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default Valid8LandingPage;
