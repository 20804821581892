import { trackHubSpotPageView } from 'helpers/hubspot';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

import Footer from 'components/landing/Footer';
import NavbarStandard from 'components/landing/NavbarStandard';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { Container } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

const Valid8SpinWheel = () => {
  const pageTitle = 'Pacific Marketing Labs - Spin To Win';
  const navigate = useNavigate();
  const breakpoints = useBreakpoints();

  const isSnap = navigator.userAgent.toLowerCase().includes('reactsnap');

  useEffect(() => {
    if (isSnap) {
      return;
    }
    trackHubSpotPageView(
      'Pacific Marketing Labs - Spin To Win',
      '/valid8/spin-to-win'
    );
    const gameScript = document.createElement('script');

    gameScript.innerHTML = `
			!function(d,o,t,v,u){var l="dataLayer";d[v]=d[v]||function(){(d[v][l]=d[v][l]||[]).push.apply(d[v][l],arguments)},d[v][l]=d[v][l]||[];var s=o.createElement(t),f=o.getElementsByTagName(t)[0];for(var k in u)Object.prototype.hasOwnProperty.call(u,k)&&(s[k]=u[k]);f&&f.parentNode?f.parentNode.insertBefore(s,f):o.head.appendChild(s)}(window,document,"script","dotVu",{async:!0,src:"https://cdn.dot.vu/core/dot.js"});
			window["dotVu"]({ eventType: "init", accountId: "9667" });
			window["dotVu"]({ eventType: "projectLoad", accountId: "9667", details: {"config":{"dotId":9667,"pageId":"668fd22b939d4d046826671c","projectTitle":"Pacific Marketing Labs Spin and Win"},"options":{"type":"inline","selector":"#dot-7e3f92fb-4cb5-4dca-b66c-f38ca277b6e6","height":"auto","width":"100%","ratio":null,"gaCrossDomainTracking":false,"title":""}} });
		`;
    gameScript.async = true;

    document.body.appendChild(gameScript);

    console.log('gameScript', gameScript);

    return () => {
      document.body.removeChild(gameScript);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Spin for your chance to win credits to clean your HubSpot contacts"
        />
        <meta
          name="keywords"
          content="Currency-R8, Valid8, Currency-R8 Help, Valid8 Help, Pacific Marketing Labs support, Hubspot Currency Conversion support, HubSpot email verficication support"
        />
        {/* LinkedIn Card */}
        <meta
          property="og:title"
          content="Want to clean your HubSpot contacts?"
        />
        <meta
          property="og:site_name"
          content="https://www.pacificmarketinglabs.io/valid8/spin-to-win"
        />
        <meta
          property="og:url"
          content="https://www.pacificmarketinglabs.io/valid8/spin-to-win"
        />
        <meta
          property="og:image"
          content="https://www.pacificmarketinglabs.io/images/landingpages/valid8_spin_wheel.png"
        />
      </Helmet>
      <div className="landing-page" id="valid8-game">
        {/*<NavbarStandard />*/}
        <div
          // className="landing-gutter"
          style={{
            minHeight: '100vh',
            maxWidth: 'calc(100vw-144px)',
            backgroundColor: '#2F4450'
          }}
        >
          {/*<h1 className="landing-header" style={{ paddingTop: 90 }}>
            Want to clean your HubSpot{' '}
            <span className="text-red">contacts</span>?
          </h1>
          <p className="apphero-description" style={{ marginBottom: 0 }}>
            Want to clean your contacts?...you need a booster pack to make that
            happen. Your chance to win a pack of 1,000 - 50,000 Valid8 credits
            to clean your contacts. You will also win time with one of our
            HubSpot experts to implement a workflow.
            <br />
            <br />
            If you're an existing customer we will add the booster pack to your
            account. If not we will invite you to a free trial and kickstart
            your account with these free credits.
          </p>*/}
          <div
            className="bg-blue"
            style={{
              height: '100vh',
              display: 'flex',
              alignItems: 'center',
              width: '100vw'
            }}
          >
            {/*<div style={{ marginTop: 64 }}>*/}
            <div
              style={{ width: '100vw' }}
              id="dot-7e3f92fb-4cb5-4dca-b66c-f38ca277b6e6"
            ></div>
          </div>
        </div>
        {/*<Footer />*/}
      </div>
    </>
  );
};

export default Valid8SpinWheel;
