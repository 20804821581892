import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import Footer from 'components/landing/Footer';
import NavbarStandard from 'components/landing/NavbarStandard';
import Testimonial from 'components/landing/Testimonial';
import Testimonials from 'components/landing/Testimonials';
import AppFAQ from 'components/landing/app-landing/AppFAQ';
import AppFeature from 'components/landing/app-landing/AppFeature';
import AppHero from 'components/landing/app-landing/AppHero';
import AppPricing from 'components/landing/app-landing/AppPricing';
import { trackHubSpotPageView } from 'helpers/hubspot';
import { getUTMUrl } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Helmet from 'react-helmet';

const CR8LandingPage = () => {
  const [products, setProducts] = useState([]);
  const [currency, setCurrency] = useState('USD');

  const getSymbol = currency => {
    switch (currency) {
      case 'USD':
        return '$';
      case 'EUR':
        return '€';
      case 'GBP':
        return '£';
    }
  };

  useEffect(() => {
    if (navigator.userAgent === 'ReactSnap') {
      return;
    }

    trackHubSpotPageView(
      'Pacific Marketing Labs - Currency-R8',
      '/currency-r8'
    );

    const hsMeetingScript = document.createElement('script');

    hsMeetingScript.src =
      'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    hsMeetingScript.async = true;

    document.body.appendChild(hsMeetingScript);

    return () => {
      document.body.removeChild(hsMeetingScript);
    };
  }, []);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_APP_URL}/subscriptions/get-products`,
        {
          app: 'currencyr8',
          currency: currency
        },
        {
          headers: {
            'Access-Control-Allow-Origin': process.env.REACT_APP_APP_URL
          }
        }
      )
      .then(res => {
        setProducts(res.data);
      })
      .catch(err => {
        // console.log(err);
      });
  }, [currency]);

  return (
    <>
      <Helmet>
        <title>Pacific Marketing Labs - Currency-R8</title>
        <meta
          name="description"
          content="Currency-R8 brings real-time currency-conversion and scheduled daily exchange rate updates to your HubSpot portal."
        />
        <meta
          name="keywords"
          content="Currency-R8, HubSpot currency converter, change currency HubSpot, change deal currency HubSpot, change currency in HubSpot CRM, ultimate currency converter HubSpot, HubSpot multiple currencies"
        />
      </Helmet>
      <div className="landing-page">
        <NavbarStandard />
        <AppHero>
          <AppHero.Description
            videourl="https://www.youtube.com/embed/xGC1DY8tbmw"
            title="Currency-R8"
            columns={[6, 6]}
            certified
          >
            <p className="apphero-title text-black">
              Currency conversion app for HubSpot
            </p>
            <ul>
              <li>
                Instantly lookup the value of a deal in 150+ currencies using
                real-time exchange rates
              </li>
              <li>
                Schedule automatic daily, weekly or monthly exchange rate
                updates of all your HubSpot currencies
              </li>
              <li>Auto-update deal and line items to new currency value</li>
              <li>Automate currency conversion within HubSpot workflows</li>
            </ul>
          </AppHero.Description>
        </AppHero>
        <AppFeature bgcolor="lightblue">
          <AppFeature.Title>
            <span className="text-yellow">Schedule </span>
            <span className="text-white">
              {' '}
              DAILY, WEEKLY OR MONTHLY UPDATES
            </span>
          </AppFeature.Title>
          <AppFeature.Content
            imgpos="E"
            imgurl="/images/landingpages/cr8_15.png"
          >
            <p className="appfeature-description text-white">
              Simply set the daily update time, select which currencies you want
              updated and leave Currency-R8 to do the rest.
            </p>
            <ul className="appfeature-description text-white">
              <li>
                Choose from real-time, average of past 30 days or average of
                month-to-date rates.
              </li>
              <li>
                Select which of your HubSpot currencies you want to update.
              </li>
              <li>
                Update all HubSpot currency exchange rates instantly, on demand.{' '}
              </li>
            </ul>
            <Button
              className="ourapps-learnmore"
              style={{ marginTop: 16 }}
              variant="danger"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_BASE_URL}/signup`,
                  '_blank'
                );
              }}
            >
              Get Started For Free{' '}
              <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
            </Button>
          </AppFeature.Content>
        </AppFeature>
        <AppFeature bgcolor="blue" anchor={true}>
          <AppFeature.Title>
            <span className="text-yellow">Instant Currency Conversion</span>
            <span className="text-white"> Inside HubSpot</span>
          </AppFeature.Title>
          <AppFeature.Content
            imgpos="S"
            imgurl="/images/landingpages/cr8_00.png"
          >
            <p className="appfeature-description text-white">
              You know the scenario. You're on a call with a hot prospect, they
              love what you've got and are ready to buy, but they just want to
              know how much it will be in their local currency...
              <br />
              <br /> You quickly search for a currency converter, or reach for
              an app on your phone, whilst your hot prospect sits patiently
              waiting for you to work it out.
              <br />
              <br />
              No more. Say goodbye to scrambling. With Currency-R8, there's no
              need to leave HubSpot, our app sits right inside your deal screen.
            </p>
          </AppFeature.Content>
        </AppFeature>
        <AppFeature bgcolor="grey75">
          <AppFeature.Title>
            <span className="text-yellow">Convert </span>
            <span className="text-white"> On Demand</span>
          </AppFeature.Title>
          <AppFeature.Content
            imgpos="E"
            imgurl="/images/landingpages/cr8_10.png"
          >
            <p className="appfeature-description text-white">
              Currency-R8 sits right inside the deal screen and automatically
              recognises your existing deal currency and amount.
            </p>
            <br />
            <p className="appfeature-description text-white">
              Instantly lookup the value of your deal in 150+ currencies by
              simply clicking a button and selecting the currency you require.
            </p>
            <br />
            <p className="appfeature-description text-white">
              Choose to automatically update your deal and line items with the
              new currency and amount, copy the new amount to the clipboard for
              use elsewhere or simply close the lookup window with no further
              action, the choice is yours.
            </p>
            <Button
              className="ourapps-learnmore"
              style={{ marginTop: 16 }}
              variant="danger"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_BASE_URL}/signup`,
                  '_blank'
                );
              }}
            >
              Get Started For Free{' '}
              <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
            </Button>
          </AppFeature.Content>
        </AppFeature>
        <AppFeature bgcolor="grey50">
          <AppFeature.Title>
            <span className="text-yellow">Workflow </span>
            <span className="text-white"> Automation</span>
          </AppFeature.Title>
          <AppFeature.Content
            imgpos="E"
            imgurl="/images/landingpages/cr8_20.png"
          >
            <p className="appfeature-description text-white">
              Need to convert foreign currency deals back to your base currency
              when a deal closes or reaches a specific stage?
            </p>
            <br />
            <p className="appfeature-description text-white">
              Got a sales rep who services a foreign market and needs their
              deals automatically converted to the local currency?
            </p>
            <br />
            <p className="appfeature-description text-white">
              No problem. Currency-R8 can be dropped straight into your HubSpot
              workflows, just select it from the list of actions, select which
              currency you want to convert to/from and let Currency-R8 handle
              the rest.
            </p>
            <Button
              className="ourapps-learnmore"
              style={{ marginTop: 16 }}
              variant="danger"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_BASE_URL}/signup`,
                  '_blank'
                );
              }}
            >
              Get Started For Free{' '}
              <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
            </Button>
          </AppFeature.Content>
        </AppFeature>
        <AppFeature bgcolor="grey25">
          <AppFeature.Title>
            Dashboards &amp; <span className="text-red"> Reporting</span>
          </AppFeature.Title>
          <AppFeature.Content
            imgpos="E"
            imgurl="/images/landingpages/cr8_40.png"
          >
            <p className="appfeature-description">
              Currency-R8 includes a real-time dashboard so you can monitor
              usage of the app in real-time.
            </p>
            <br />
            <p className="appfeature-description">
              View your team's most commonly requested currency conversions to
              better understand how your customer want to buy.
            </p>
            <br />
            <p className="appfeature-description">
              View history of all conversions and rates at that point in time.
            </p>
            <br />
            <p className="appfeature-description">
              Manage your plan - our simple pricing makes it easy to ensure you
              never run out of credits.
            </p>
            <Button
              className="ourapps-learnmore"
              style={{ marginTop: 16 }}
              variant="danger"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_BASE_URL}/signup`,
                  '_blank'
                );
              }}
            >
              Get Started For Free{' '}
              <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
            </Button>
          </AppFeature.Content>
        </AppFeature>
        <Testimonials bgcolor="bg-grey10">
          <Testimonial>
            <Testimonial.Quote>
              The whole team is very helpful and they won't rest until they
              resolve all the queries that you might have for them. The
              Currency-R8 integration is amazing in terms of what is providing.
            </Testimonial.Quote>
            <Testimonial.Name>Mario Todorovski</Testimonial.Name>
            <Testimonial.Company>
              Finance & Office Manager, Pabau
            </Testimonial.Company>
          </Testimonial>
          <Testimonial>
            <Testimonial.Quote>
              Currency-R8 does what it says on the tin. Ensures accurate values
              in our open pipeline and forecast, rather than leaving it up to
              manual updates of FX rates on an ad-hoc basis. Absolute no-brainer
              if you deal in multiple territories.
            </Testimonial.Quote>
            <Testimonial.Name>Sam Statham</Testimonial.Name>
            <Testimonial.Company>
              RevOps Support, AutomatePro
            </Testimonial.Company>
          </Testimonial>
          <Testimonial>
            <Testimonial.Quote>
              Currency-R8 is a great app for automating currency rate
              conversations. The product has been simple to use and setup. Not
              only has the integration worked seamlessly, we were especially
              pleased with their customer service and support throughout the
              process. This is an easy to use tool for currency conversion
              automations with truly caring people behind the scenes.
            </Testimonial.Quote>
            <Testimonial.Name>Rachel Thayer-Cooney</Testimonial.Name>
            <Testimonial.Company>
              Data & Analytics Manager, Joorney
            </Testimonial.Company>
          </Testimonial>
        </Testimonials>
        <AppPricing currency={currency} setCurrency={setCurrency}>
          {products.length !== 0 && (
            <>
              <AppPricing.Price
                name="Free Trial"
                currency={currency}
                price={
                  products.filter(product => product.Name === 'Free Trial')[0]
                    .Price
                }
                features={JSON.parse(
                  products.filter(product => product.Name === 'Free Trial')[0]
                    .Description
                )}
              />
              <AppPricing.Price
                name="Essential"
                currency={currency}
                price={
                  products.filter(product => product.Name === 'Essential')[0]
                    .Price
                }
                features={JSON.parse(
                  products.filter(product => product.Name === 'Essential')[0]
                    .Description
                )}
              />
              <AppPricing.Price
                name="Pro"
                currency={currency}
                price={
                  products.filter(product => product.Name === 'Pro')[0].Price
                }
                features={JSON.parse(
                  products.filter(product => product.Name === 'Pro')[0]
                    .Description
                )}
              />
              <AppPricing.Price
                name="Enterprise"
                currency={currency}
                price="Call"
                features={[
                  products.filter(product => product.Name === 'Enterprise')[0]
                    .Description
                ]}
              />
            </>
          )}
        </AppPricing>
        <AppFAQ>
          <AppFAQ.Section title="General">
            <AppFAQ.Question>What is Currency-R8?</AppFAQ.Question>
            <AppFAQ.Answer>
              Currency-R8 is a HubSpot app that plugs straight into your HubSpot
              portal. It adds a card to your Deals screen so you can run
              currency conversions on demand and also adds a new action to
              workflows allowing you to bake currency conversion right into your
              deal workflows.
            </AppFAQ.Answer>
            <AppFAQ.Question>Who runs Currency-R8?</AppFAQ.Question>
            <AppFAQ.Answer>
              Currency-R8 is provided and managed by Pacific Marketing Labs Ltd,
              Jordans Courtyard, 8 Upper High Street, Thame, Oxfordshire, United
              Kingdom, OX9 3ER
            </AppFAQ.Answer>
            <AppFAQ.Question>
              Can I run currency conversions instantly, on demand?
            </AppFAQ.Question>
            <AppFAQ.Answer>
              Yes, absolutely you can, this is exactly what it's designed for.
              Just click the button on the Currency-R8 card on your Deals screen
              then select the currency of your choice to instantly convert the
              deal and line-item amounts to the new currency. You can then
              either close the window, copy the new amount to the clipboard or
              select to update the deal (and line-items) automatically for you.
            </AppFAQ.Answer>
            <AppFAQ.Question>
              Can I use Currency-R8 to add a currency conversion to my workflow
              automation?
            </AppFAQ.Question>
            <AppFAQ.Answer>
              Yes, with any of our paid plans you can use Currency-R8 to provide
              currency conversion actions with a HubSpot workflow. Currency-R8
              has been designed to work seamlessly with HubSpot's workflow
              engine. Just select Currency-R8 from the list of actions then
              choose what currency you would like the deal and line-items to be
              converted to. Currency-R8 takes care of the rest, your deal will
              be automatically updated to reflect the new currency and amount.
            </AppFAQ.Answer>
          </AppFAQ.Section>
          <AppFAQ.Section title="Data Sources">
            <AppFAQ.Question>
              How accurate is Currency-R8 and where does the data come from?
            </AppFAQ.Question>
            <AppFAQ.Answer>
              We use third-party services who source their spot exchange rates
              from a broad base of commercial sources and banks around the
              world, each of varying types and frequencies. The larger weighting
              particularly for major currencies is towards commercial sources as
              they more accurately reflect the trading occurring in the markets.
            </AppFAQ.Answer>
            <AppFAQ.Question>
              How often is exchange rate data refreshed?
            </AppFAQ.Question>
            <AppFAQ.Answer>
              We guarantee that all exchange rates will be refreshed at least
              once every 60 minutes, so you know you are getting an up-to-date
              rate at all times.
            </AppFAQ.Answer>
            <AppFAQ.Question>
              What currencies are supported by Currency-R8?
            </AppFAQ.Question>
            <AppFAQ.Answer>
              Currency-R8 supports over 150 currencies.
            </AppFAQ.Answer>
          </AppFAQ.Section>
          <AppFAQ.Section title="Getting Started">
            <AppFAQ.Question>How do I sign up for Currency-R8?</AppFAQ.Question>
            <AppFAQ.Answer>
              Signing up for Currency-R8 is simple, and free to get started.
              Register now for a trial version{' '}
              <a
                className="text-red"
                href={`${process.env.REACT_APP_BASE_URL}/signup`}
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </AppFAQ.Answer>
            <AppFAQ.Question>
              How do I get the Currency-R8 app on my HubSpot portal?
            </AppFAQ.Question>
            <AppFAQ.Answer>
              Once you've signed up and connected Pacific Marketing Labs to your
              HubSpot portal, installing Currency-R8 to your HubSpot portal is
              very straight forward, you simply click the install button, and
              our software will take care of the rest.
            </AppFAQ.Answer>
          </AppFAQ.Section>
          <AppFAQ.Section title="Plans &amp; Pricing">
            <AppFAQ.Question>
              Which plan is most suitable for me?
            </AppFAQ.Question>
            <AppFAQ.Answer>
              This will depend on your expected usage. All paid plans come with
              a number of bundled credits but also include provisions for
              'overuse' so your team can continue to use the app beyond your
              monthly bundled allowance.
            </AppFAQ.Answer>
            <AppFAQ.Question>
              What is meant by a 'request credit'?
            </AppFAQ.Question>
            <AppFAQ.Answer>
              A 'request credit' is spent each time you request the value of a
              deal to be converted to another currency.
            </AppFAQ.Answer>
            <AppFAQ.Question>
              How does the Professional plan work for 500+ requests?
            </AppFAQ.Question>
            <AppFAQ.Answer>
              Our Professional (Pro) plan includes 500 request credits per
              month. If you use these, the app will continue to run, and you
              will be charged per additional request. Please see our pricing for
              current charges.
            </AppFAQ.Answer>
            <AppFAQ.Question>How do I pay?</AppFAQ.Question>
            <AppFAQ.Answer>
              All payments are made by credit card on a recurring subscription.
              We offer the option of paying in US Dollar (USD), British Pound
              Sterling (GBP) or Euro (EUR). Your plan subscription will be
              charged to your card in advance on the first day of your plan each
              month. Overage charges (for Pro and Enterprise customers) will be
              charged retrospectively on the following first day of your monthly
              plan. For example, if you sign up for a Pro plan on the 15 August
              2022, you will be charged for the first month's subscription when
              you sign up. On the 15 September 2022 you will charged for the
              second month's subscription plus any overuse charges due for the
              period 15 August 2022 through to 14 September 2022 inclusive.
            </AppFAQ.Answer>
            <AppFAQ.Question>
              Do I need a credit card for the free version?
            </AppFAQ.Question>
            <AppFAQ.Answer>
              No, you can sign up and start using Currency-R8 immediately
              without providing any credit card information.
            </AppFAQ.Answer>
          </AppFAQ.Section>
        </AppFAQ>
        <Container fluid className="bg-grey10">
          <div
            className="meetings-iframe-container"
            data-src="https://meetings.hubspot.com/justinshardlow/currency-r8-demo?embed=true"
          ></div>
        </Container>
        <Footer />
      </div>
    </>
  );
};
export default CR8LandingPage;
